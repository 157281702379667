import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { api } from "../utilities/redux-api/api";
import { vendor_category_api } from "../utilities/redux-api/vendor_category/api";
import { sap_api } from "../utilities/redux-api/sap/sap_api";

export default configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware)
      .concat(vendor_category_api.middleware)
      .concat(sap_api.middleware),
});
