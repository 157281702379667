import React, { useEffect, useState } from "react";
import {
  useGetUsersListQuery,
  useUpdateStatusMutation,
} from "../../../../utilities/redux-api/api";
import TableComponent from "../../../../common/sharedComponents/Table";
import { support_role_endpoint } from "../../../../utilities/redux-api/endpoint";
import { history } from "../../../../app/history";
import { message } from "antd";

function UserTableContent({ search }) {
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const { isLoading, data } = useGetUsersListQuery({
    page,
    per_page,
    search: debouncedSearch,
  });
  const [
    updateStatus,
    // { isLoading: statusLoding }
  ] = useUpdateStatusMutation();

  const handleEdit = (row) => {
    history.push(`/app/${support_role_endpoint.user}/edit/${row.id}`);
  };
  const handleView = (row) => {
    history.push(`/app/${support_role_endpoint.user}/${row.id}`);
  };

  const handleChangeStatus = async (row) => {
    try {
      await updateStatus(row?.id).unwrap();

      message.success("done");
    } catch (error) {
      if (error?.data) {
        message.error(
          error.data.message || "An error occurred while updating data"
        );
      } else {
        message.error("An unexpected error occurred");
      }
    }
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      isLink: true,
      getLink: (row) => `/app/${support_role_endpoint.user}/${row.id}`,
    },
    { field: "contact_person_email", headerName: "Email" },
    { field: "company_name", headerName: "company name", isArray: true },
    { field: "role_name", headerName: "role name" },
    { field: "status", headerName: "is Active" },
    {
      field: "createdAt",
      headerName: "date",
      isDate: true,
      format: "DD MMM YYYY",
    },
    {
      field: "status",
      headerName: "Actions",
      isActions: true,
      actions: ["edit", "view", "status"],
      onEdit: handleEdit,
      onView: handleView,
      onStatusChange: handleChangeStatus,
    },
  ];

  // Debounce the search input to avoid too many API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      if (search || search === "") {
        setDebouncedSearch(search);
      }
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);
  return (
    <div>
      <TableComponent
        column={columns}
        data={data?.data}
        isLoading={isLoading}
        pagination={data?.pagination}
        page={page}
        per_page={per_page}
        setPage={setPage}
        setPer_page={setPer_page}
      />
    </div>
  );
}

export default UserTableContent;
