import React, { useState } from "react";
import { VendorInstructions, VendorListHolder } from "./vendorReg";
import info from "../../../../assets/icon.png";
import styled from "styled-components";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { styled as styled1 } from "@mui/material/styles";
import { StyledCard } from "../StepMain";
import RecipeReviewCard from "./cardTrial";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { bussinesslocations } from "../../redux/vendorRegistrationSlice";
import { history } from "../../../../app/history";
import LocalStorage from "../../../../utilities/localStorage";
import LoadingButton from "@mui/lab/LoadingButton";
import { message } from "antd";
import { hasDuplicates } from "../../../../utilities/helper";
import { getNotification } from "../../../notification/redux/notificationSlice";
function BankReg({
  activeStep,
  handleBack,
  registrationtype,
  createdUser,
  setActiveStep,
  setSkipped,
  isStepSkipped,
  skipped,
  clientId,
  is_domestic,
}) {
  const user = LocalStorage.getItem("tata_login_user");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [expanded, setExpanded] = useState({});
  const [locations, setLocations] = useState([]);
  const [contacts, setContacts] = useState({});
  const [location, setLocation] = useState(true);

  const handleExpandClick = (ind) => {
    setExpanded({
      ...expanded,
      [ind]: !expanded[ind],
    });
  };

  const handleSetContacts = (ind, val) => {
    setContacts({
      ...contacts,
      [ind]: val,
    });
  };

  const dispatch = useDispatch();
  const handleSave = () => {
    const uniqueData = locations.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.address_line_one === obj.address_line_one &&
            t.address_line_two === obj.address_line_two &&
            t.location === obj.location &&
            t.city === obj.city &&
            t.state === obj.state
        )
    );
    if (uniqueData.length !== locations.length) {
      return message.error("Duplicate location");
    }
    const isDuplicate = hasDuplicates(
      locations,
      [],
      ["contacts", "phone", "branch_email"]
    );
    if (isDuplicate) {
      return message.error("Duplicate location");
    }

    let data = locations.map((item, index) => {
      let modifiedContacts = (contacts[index] || []).map((con) => {
        delete con.edit;
        return con;
      });

      return {
        ...item,
        user_id: user.roles[0] === "officer" ? createdUser?.id : "",
        contacts: modifiedContacts,
      };
    });
    // if (user.roles[0] === "officer") {
    //   data.push({ user_id: createdUser?.id });
    // }
    var formData = new FormData();
    setLoading(true);
    formData.append("data", JSON.stringify(data));
    for (let i = 0; i < data.length; i++) {
      formData.append("gst_document", data[i].gst_document_image_name);
    }
    // if (Object.keys(formData).length === 0) {
    //   // if (!data.length) {
    //   message.info("Add at least one business location");
    //   setLoading(false);
    //   return;
    //   // }
    // }

    if (registrationtype === "unregistered" || !registrationtype) {
      data = data.filter(
        (obj) =>
          obj.branch_email.trim() !== "" &&
          obj.phone.trim() !== "" &&
          obj.location !== ""
      );
    } else {
      data = data.filter(
        (obj) =>
          obj.branch_email.trim() !== "" &&
          obj.phone.trim() !== "" &&
          obj.location !== "" &&
          obj.gst_document_image_name !== ""
      );
    }
    if (!data.length) {
      message.info("Add at least one business location");
      setLoading(false);
      return;
    }

    dispatch(bussinesslocations(formData)).then((response) => {
      if (response?.payload?.success) {
        if (user.roles[0] === "user") {
          history.push("/app");
        }
        if (user.roles[0] === "officer") {
          let newSkipped = skipped;
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
          }
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        }
        dispatch(getNotification());
      } else {
        setLoading(false);
      }
    });
  };

  const handleAddLocation = () => {
    let locationsCopy = [...locations];
    locationsCopy.push({
      gst_id: "",
      location: "",
      phone: "",
      branch_email: "",
      fax_number: "",
      location_state: "",
      gst_document_image_name: "",
    });
    if (clientId === 2) {
      setLocation(false);
    }
    handleExpandClick(locationsCopy.length - 1);
    setLocations(locationsCopy);
  };

  const handleLocationDelete = (index) => {
    let locationsCopy = [...locations];
    // eslint-disable-next-line
    locationsCopy = locationsCopy.filter((_, i) => i != index);

    setLocations(locationsCopy);
  };

  const handleLocationOnchange = (e, index) => {
    const { name, value } = e.target;

    let locationsCopy = [...locations];

    locationsCopy[index] = {
      ...(locationsCopy[index] || {}),
      [name]: value,
    };

    setLocations(locationsCopy);
  };

  return (
    <div>
      <StyledCard>
        <CardContent>
          <VendorInstructions>
            <img src={info} alt="info" /> Instructions
          </VendorInstructions>
          <br />
          <VendorListHolder>
            <li>
              This is a API enabled form. E-mail and GSTIN data will need to be
              validated automatically before you can submit it.
            </li>
            <li>
              Click on Validate below the GSTIN field and select the location
              from the drop down list in the location field.
            </li>
            <li style={{ paddingBottom: "5px" }}>
              Address details will be autopopulated and are not editable.
            </li>
          </VendorListHolder>
        </CardContent>
      </StyledCard>

      <form onSubmit={handleSubmit(handleSave)}>
        {locations.map((location_item, index) => (
          <RecipeReviewCard
            key={index}
            l_index={index}
            location_item={location_item}
            register={register}
            errors={errors}
            expanded={expanded[index] || false}
            handleExpandClick={handleExpandClick}
            contacts={contacts[index] || []}
            setContacts={(value) => handleSetContacts(index, value)}
            onChange={handleLocationOnchange}
            onDelete={handleLocationDelete}
            registrationtype={registrationtype}
            createdUser={createdUser}
            clientId={clientId}
            is_domestic={is_domestic}
          />
        ))}
        {location ? (
          <AddBusinessCard>
            <CardContent>
              <Grid onClick={handleAddLocation}>
                <LocationDiv>+ Add business location</LocationDiv>
              </Grid>
            </CardContent>
          </AddBusinessCard>
        ) : (
          ""
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Button
            color="inherit"
            disabled={!activeStep}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {/* <Box sx={{ flex: "1 1 auto" }} /> */}

          <LoadingButton
            sx={{
              textTransform: "none",
              background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            {user.roles[0] === "officer" ? "Save & Next" : "Submit"}
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
}

export default BankReg;

export const LocationDiv = styled.div`
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #271fe0;
  :hover {
    cursor: pointer;
  }
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorLabelHolder = styled.div`
  text-align: left;
  padding: 0 20px 20px 20px;
`;

export const StyledContainedButton = styled(Button)`
  text-transform: none;
  background-color: #dcdce4;
  margin-left: 15px;
  color: white;
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  padding: 5px 30px 5px 30px;

  :active {
    color: grey;
  }
  :hover {
    border: 1px solid var(--button-color);
    background-color: white;
    color: black;
  }
`;

export const StyledTextField = styled(TextField)`
  font-family: var(--font-family-med);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 320px;
  outline: none;
  /* Neutral/200 */
  ::placeholder {
    font-family: var(--font-family-med);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  ::after {
    border-bottom: 1px solid #dcdce4;
  }

  :active {
    border-bottom: 1px solid #dcdce4;
  }
  border-bottom: 1px solid #dcdce4;
`;

const AddBusinessCard = styled1(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;

border: 1px dashed #C0c0cf;
height: 70px;
`;
