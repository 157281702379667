import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  // Tooltip,
  useTheme,
} from "@mui/material";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
// import moment from "moment";
// import { Link } from "react-router-dom";
import { ApproveButton } from "../../../vendorRegistration/components/ViewvendorDetails";
import {
  getPrGrantedUsers,
  getPrPendingUsers,
  getPrRevokedUsers,
  revokePRPrivilege,
} from "../../redux/userPrivilegesSlice";
import { useEffect } from "react";
import { EmptyContainer } from "../../../../utilities/helper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function PrGrantedContent({ prGrantedListInfo }) {
  const { prGrantedList, pagination, loading } = useSelector(
    (state) => state.userPrivileges
  );
  const [checkedItems, setCheckedItems] = React.useState({});
  const [per_page, setPer_page] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (prGrantedList.length > 0) {
      // Create an object with record IDs as keys and true as values
      const initialCheckedItems = prGrantedList.reduce((acc, record) => {
        acc[record.id] = true;
        return acc;
      }, {});
      setCheckedItems(initialCheckedItems);
    }
  }, [prGrantedList, setCheckedItems]);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const newCheckedItems = prGrantedList.reduce((acc, record) => {
      acc[record.id] = isChecked;
      return acc;
    }, {});
    setCheckedItems(newCheckedItems);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, id) => {
    setCheckedItems({ ...checkedItems, [id]: event.target.checked });
  };

  const handleRevoke = () => {
    const selectedUserIds = Object.entries(checkedItems)
      .filter(([id, isChecked]) => isChecked)
      .map(([id]) => parseInt(id));

    if (selectedUserIds.length === 0) {
      // Handle case when no users are selected
      return;
    }

    const payload = { userIds: selectedUserIds };
    dispatch(revokePRPrivilege(payload)).then((response) => {
      setOpen(false);
      if (response.payload.success === true) {
        const payload = {
          per_page,
          page,
          // search,
        };
        dispatch(getPrPendingUsers(payload));
        dispatch(getPrGrantedUsers(payload));
        dispatch(getPrRevokedUsers(payload));
      }
    });
  };

  useEffect(() => {
    setPage(1);
  }, [per_page]);

  useEffect(() => {
    const payload = {
      per_page,
      page,
      // search,
    };
    prGrantedListInfo(payload);
    // eslint-disable-next-line
  }, [page, per_page]);

  return (
    <>
      <CustomTableContainer component={Card}>
        <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell align="left">
                <Checkbox
                  checked={
                    prGrantedList.length > 0 &&
                    Object.values(checkedItems).every(Boolean)
                  }
                  indeterminate={
                    prGrantedList.length > 0 &&
                    Object.values(checkedItems).some(Boolean) &&
                    !Object.values(checkedItems).every(Boolean)
                  }
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Sr. No.</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Name</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Email</TableHeaderFont>
              </TableCell>
              {/* <TableCell align="center">
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell> */}
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : prGrantedList.length !== 0 ? (
              prGrantedList.map((row, index) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Checkbox
                        checked={checkedItems[row.id] || false}
                        onChange={(event) => handleChange(event, row.id)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>{index + 1}</TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.first_name !== undefined &&
                        row.first_name !== null
                          ? `${row.first_name + " " + row.last_name}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.contact_person_email !== undefined &&
                        row.contact_person_email !== null
                          ? `${row.contact_person_email}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    {/* <TableCell style={{ textAlign: "center" }}>
                  <Tooltip title="View User">
                    <Link
                      style={{ textDecoration: "none" }}
                      // eslint-disable-next-line
                      to={"/app/users-list-view/" + `${row.id}`}
                    >
                      <RemoveRedEyeIcon
                        style={{
                          fill: "royalblue",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    </Link>
                  </Tooltip>
                </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomTableContainer>
                    <EmptyContainer
                      text={`There are no granted users to display`}
                    />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={pagination?.total_page}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
      <br></br>
      <ApproveButton
        onClick={handleClickOpen}
        variant="outlined"
        style={{
          height: "30px",
          width: "100px",
          float: "right",
        }}
      >
        Revoke
      </ApproveButton>
      {/* add file dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to revoke PR creation privileges from the
            selected users?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={handleRevoke}
            autoFocus
            size="small"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default PrGrantedContent;
