import React, { useEffect, useState } from "react";
import TableComponent from "../../../common/sharedComponents/Table";
import { useGetAllCategoriesQuery } from "../../../utilities/redux-api/vendor_category/api";

const CategoryTableContent = ({ search }) => {
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const { isLoading, data } = useGetAllCategoriesQuery({
    page,
    per_page,
    search: debouncedSearch,
  });
  const columns = [
    {
      field: "type",
      headerName: "type",
    },
    { field: "type_code", headerName: "Type Code" },
    { field: "sap_name", headerName: "SAP" },
    // {
    //   field: "status",
    //   headerName: "Actions",
    //   isActions: true,
    //   actions: ["delete"],
    //   onDelete: () => {
    //     console.log("deleted");
    //   },
    // },
  ];
  useEffect(() => {
    const handler = setTimeout(() => {
      if (search || search === "") {
        setDebouncedSearch(search);
      }
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  return (
    <div>
      <TableComponent
        column={columns}
        data={data?.data?.data}
        isLoading={isLoading}
        pagination={data?.data?.pagination}
        page={page}
        per_page={per_page}
        setPage={setPage}
        setPer_page={setPer_page}
      />
    </div>
  );
};

export default CategoryTableContent;
