import React from "react";
import FormComponent from "../../../common/sharedComponents/Form";
import { useGetAllSAPQuery } from "../../../utilities/redux-api/sap/sap_api";
import { useAddCategoryMutation } from "../../../utilities/redux-api/vendor_category/api";
import { endpoint } from "../../../utilities/redux-api/endpoint";
import { history } from "../../../app/history";
import { message } from "antd";

const CategoryAddContent = () => {
  const { data: dropdown } = useGetAllSAPQuery(null);
  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const fields = [
    {
      name: "type_code",
      label: "Type Code",
      type: "text",
      placeholder: "Enter your Type Code Here",
      required: true,
      requiredMessage: "Type Code is required",
    },
    {
      name: "type",
      label: "Type",
      type: "text",
      placeholder: "Enter your Type here",
      required: true,
      requiredMessage: "Type is required",
    },
    {
      name: "sap_name",
      label: "Select SAP ",
      type: "dropdown",
      isMultiple: false,
      options: dropdown,
      required: true,
      requiredMessage: "SAP is required",
    },
  ];

  const onSubmit = async (data) => {
    console.log("data: ", data);
    try {
      await addCategory({
        type: data?.type,
        type_code: data?.type_code,
        sap_id: data?.sap_name?.key,
      }).unwrap();
      message.success("Category add successfully!");
      history.push(`/app/${endpoint.vendor_category}`);
    } catch (error) {
      if (error?.data) {
        message.error(
          error.data.message || "An error occurred while updating data"
        );
      } else {
        message.error("An unexpected error occurred");
      }
    }
  };
  return (
    <>
      <FormComponent
        initialValues={null}
        onSubmit={onSubmit}
        fields={fields}
        heading={"Add Details"}
        isLoading={isLoading}
      />
    </>
  );
};

export default CategoryAddContent;
