import React, { useState } from "react";
import {
  CustomTableContainer,
  CustomTableHead,
  TableHeaderFont,
} from "../../styles/tableContent.style";
import moment from "moment";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import {
  Delete,
  Edit,
  ToggleOff,
  ToggleOn,
  Visibility,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const TableComponent = ({
  column,
  data,
  isLoading,
  pagination,
  setPage,
  setPer_page,
  page,
  per_page,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState([]);

  //   let total_page = 100;

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDialogOpen = (data) => {
    setDialogData(data);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <CustomTableContainer component={Card}>
        <Table>
          <CustomTableHead>
            <TableRow>
              {column.map((cell, index) => {
                return (
                  <TableCell>
                    <TableHeaderFont key={`column-${cell?.field}-${index}`}>
                      {cell?.headerName}
                    </TableHeaderFont>
                  </TableCell>
                );
              })}
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
              </Box>
            ) : (
              data.map((row, index) => {
                return (
                  <TableRow
                    key={`data-${row?.id || index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {column.map((column, index) => (
                      <TableCell
                        key={`cell-${column.field}-${row.id || index}`}
                      >
                        {column.isDate ? (
                          moment(row[column.field]).format(
                            column.format || "YYYY-MM-DD, HH:mm:ss"
                          )
                        ) : column.isLink ? (
                          <Link to={column.getLink ? column.getLink(row) : "#"}>
                            {row[column.field]}
                          </Link>
                        ) : column.isActions ? (
                          <>
                            {column.actions?.includes("edit") && (
                              <IconButton onClick={() => column.onEdit(row)}>
                                <Edit color="action" fontSize="small" />
                              </IconButton>
                            )}
                            {column.actions?.includes("view") && (
                              <IconButton onClick={() => column.onView(row)}>
                                <Visibility color="info" fontSize="small" />
                              </IconButton>
                            )}
                            {column.actions?.includes("delete") && (
                              <IconButton onClick={() => column.onDelete(row)}>
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            )}
                            {column.actions?.includes("status") && (
                              <IconButton
                                onClick={() => column.onStatusChange(row)}
                              >
                                {row[column.field] === "true" ? (
                                  <Tooltip title="Inactive User">
                                    <ToggleOn
                                      color="success"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Active User">
                                    <ToggleOff color="error" fontSize="small" />
                                  </Tooltip>
                                )}
                              </IconButton>
                            )}
                          </>
                        ) : column.isArray &&
                          Array.isArray(row[column.field]) &&
                          row[column.field].length > 1 ? (
                          <Button
                            variant="outlined"
                            onClick={() => handleDialogOpen(row[column.field])}
                          >
                            {row[column.field].length} items
                          </Button>
                        ) : (
                          row[column.field] || "Not Applicable"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={pagination?.total_page ?? 1}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <List>
            {dialogData.map((item, idx) => (
              <ListItem key={`dialog-${item}-${idx}`}>{item}</ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableComponent;
