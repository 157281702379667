import React from "react";
import { StepperContentHolder } from "../../vendorRegistration/vendorRegistration";
import TenantViewContent from "./components/tenantViewContent";
import { TitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { Button, Grid, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";

const TenantView = () => {
  return (
    <div>
      <TitleHolder>
        <Grid container>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Tenant Details
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
            <Tooltip title="Edit Details">
              <Link to={"/app/support/tenant/edit"}>
                <Button variant="outlined">
                  <Edit />
                </Button>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </TitleHolder>
      <StepperContentHolder>
        <TenantViewContent />
      </StepperContentHolder>
    </div>
  );
};

export default TenantView;
