import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { styled as materialStyles } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { FileDownload } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import VRF_vishakha from "../../../assets/F-PUR-03-VRF-Vishakha.DOCX";
import {
  vendorRegistration,
  hsnCategories,
  hsnValues,
  itemValues,
  getMasterItems,
} from "../redux/vendorRegistrationSlice";
import {
  UserInfoDiv,
  VendorCardTitle,
  VendorInstructions,
  VendorListHolder,
} from "./steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import BankReg from "./steps/bankReg";
import { useSelector } from "react-redux";
import { Super, VendorCardBlankTitle } from "./steps/cardTrial";
import FormHelperText from "@mui/material/FormHelperText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
/* import ArrowRightIcon from "@mui/icons-material/ArrowRight"; */
import _without from "lodash/without";
import LocalStorage from "../../../utilities/localStorage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Clear } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { bank_info } from "../../../utilities/helper";
import { CustomDocumentLink } from "../../../common/sharedComponents/styles/card.style";

const steps = ["Vendor Details", "Business Details"];
const Input = materialStyles("input")({
  display: "none",
});

export default function StepMain() {
  const value = JSON.parse(localStorage.getItem("tata_login_user"));
  const vendorCompany = JSON.parse(localStorage.getItem("client"));
  const { categories, subcategories, itemcategories, Items } = useSelector(
    (state) => state.vendorRegistration
  );
  const [anyDocs, setAnyDocs] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [checkedPan, setCheckedPan] = useState(false);
  const [panDoc, setPanDoc] = useState("");
  const [msmeDoc, setmsmeDoc] = useState("");
  const [dealer, setDealer] = useState("");
  const [tin, setTin] = useState("");
  const [iso, setIso] = useState("");
  const [checkedType, setCheckedType] = useState("");
  // const [checkedItemType, setCheckedItemType] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [mSME, setMSME] = useState("");

  const [userinfo, setUserInfo] = useState({
    codes: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingsub, setIsLoadingsub] = useState(false);
  const [search, setSearch] = useState("");
  const [searchsub, setSearchsub] = useState("");
  const [catdisplay, setCatdisplay] = useState("none");
  const [subcatdisplay, setSubcatdisplay] = useState("none");

  const [itemcodes, setItemcodes] = useState("");
  const [isLoadingitem, setIsLoadingitem] = useState(false);
  const [searchitem, setSearchitem] = useState("");
  const [itemcatdisplay, setItemcatdisplay] = useState("none");

  // const [masterItemcodes, setMasterItemcodes] = useState("");
  const [isLoadingMasteritem, setIsLoadingMasteritem] = useState(false);
  const [mastercatdisplay, setMastercatdisplay] = useState("none");
  const [searchMasteritem, setSearchMasteritem] = useState("");
  // const [itemcatdisplay, setItemcatdisplay] = useState("none");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const onSubmit = (payload) => {
    setLoading(true);
    dispatch(vendorRegistration(payload)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        setLoading(false);
      }
    });
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...anyDocs, [index]: e.target.files[0] };

    setAnyDocs(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...anyDocs, [index]: "" };

    setAnyDocs(newDocValues);
  };
  const getCategories = (payload) => {
    dispatch(hsnCategories(payload)).then((response) => {
      if (response.payload.success === true) {
        setIsLoading(false);
      }
    });
  };

  const getItems = (payload) => {
    dispatch(getMasterItems(payload)).then((response) => {
      if (response.payload.success === true) {
        setIsLoadingMasteritem(false);
      }
    });
  };

  const getHSNCodes = (payload) => {
    dispatch(hsnValues(payload)).then((response) => {
      if (response.payload.success === true) {
        setIsLoadingsub(false);
      }
    });
  };

  const getItemsCodes = (payload) => {
    dispatch(itemValues(payload)).then((response) => {
      if (response.payload.success === true) {
        setIsLoadingitem(false);
      }
    });
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
  } = useForm();
  const [newDocs, setNewDocs] = useState([1]);
  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      any_docs: "",
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };
  const handleNext = (data) => {
    var formData = new FormData();
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_document_image", anyDocs[i]);
    }

    formData.append("data", JSON.stringify(data));
    formData.append("pan_image", panDoc);
    formData.append("msme_certificate_image", msmeDoc);
    formData.append("dealer_authorization_image", dealer);
    formData.append("tin_certificate_image", tin);
    formData.append("iso_or_rsdo_or_any_image", iso);
    formData.append("codes", JSON.stringify(userinfo.codes));
    formData.append("pan_declaration", checkedPan);

    onSubmit(formData);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCheckedPan(false);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckedPan(true);
    } else {
      setCheckedPan(false);
    }
  };

  const handleadditionalChange = (e, type) => {
    setCheckedType(type);

    type === "3" ? getItems("") : getCategories(type === "2" ? "HSN" : "SAC");
    type === "3" ? setIsLoadingMasteritem(true) : setIsLoading(true);
    if (Number(type) === "3") {
      setMastercatdisplay("block");
    } else {
      setCatdisplay("block");
    }
    type === "3" ? setSearchMasteritem("") : setSearch("");

    setItemcatdisplay("none");
    setSubcatdisplay("none");
  };

  // const handleadditionalItem = (e) => {
  //   setIsLoading(true);
  //   setSearchMasteritem("");
  //   setMasterItemcodes("block");
  // };
  const initialSelected = [];
  const [personName, setPersonName] = useState(initialSelected);
  const [registrationtype, setRegistrationtype] = useState("");
  const [msmenum, setMsmenum] = useState("");
  const [annualCr, setAnnualCr] = useState("₹0.00 CR");

  const handlecategoryChange = (e, type) => {
    setSubCategory(type);
    getHSNCodes(type);
    setIsLoadingsub(true);
    setSearchsub("");
    setSubcatdisplay("block");
    setItemcatdisplay("none");
  };

  const handlesubcategoryChange = (e, type) => {
    setItemcodes(type);
    getItemsCodes(type);
    setIsLoadingitem(true);
    setSearchitem("");
    setItemcatdisplay("block");
  };

  const handleDelete = (chipToDelete) => () => {
    let codes = userinfo.codes;

    let filtered = codes.filter((codes) =>
      codes.toLowerCase().includes(chipToDelete.toLowerCase())
    );
    const array1 = codes.filter((val) => !filtered.includes(val));
    setUserInfo({
      codes: array1,
    });
    setPersonName((current) => _without(current, chipToDelete));
    // setUserInfo({})
  };

  const handleCheckChange = (e, type) => {
    const { checked } = e.target;
    const { codes } = userinfo;
    if (checked) {
      setUserInfo({
        codes: [...codes, type],
      });
      setPersonName([...personName, type]);
    }
  };

  const handlemsme = (value) => {
    setMSME(value);
    setMsmenum("");
  };
  // const handleMsmeReg = (e) => {
  //   setMsmenum(e.target.value);
  // };

  const handleregistrationChange = (e) => {
    setRegistrationtype(e.target.value);
  };

  const code_filtered = !search
    ? categories
    : categories.filter((categories) =>
        categories.categortDescription
          .toLowerCase()
          .includes(search.toLowerCase())
      );

  const sub_filtered = !searchsub
    ? subcategories
    : subcategories.filter((subcategories) =>
        subcategories.subCategory
          .toLowerCase()
          .includes(searchsub.toLowerCase())
      );

  const item_filtered = !searchitem
    ? itemcategories
    : itemcategories.filter((itemcategories) =>
        itemcategories.item.toLowerCase().includes(searchitem.toLowerCase())
      );
  const master_item_filtered = !searchMasteritem
    ? Items
    : Items.filter((items) =>
        items.MaterialCode.toLowerCase().includes(
          searchMasteritem.toLowerCase()
        )
      );

  const radioStyles = {
    selectRadio: {
      border: "1px solid #D9D8FF",
      padding: "0px 7px 0px 7px",
      background: "#D9D8FF",
      borderRadius: "4px",
      marginLeft: "-6px",
    },
  };

  const radioStyles1 = {
    selectRadio: {
      border: "1px solid #D9D8FF",
      padding: "0px 7px 0px 7px",
      background: "#D9D8FF",
      borderRadius: "4px",
      marginLeft: "-6px",
      marginRight: "15px",
    },
  };
  var user = LocalStorage.getItem("tata_login_user");

  const is_domestic = user.is_domestic;

  // Function to convert to millions and format as currency
  const convertToMillions = (e) => {
    const num = e.target.value;
    if (num === undefined) return "";
    const millions = num / 10;

    const ready =
      millions.toLocaleString("en-IN", { style: "currency", currency: "INR" }) +
      " CR";
    setAnnualCr(ready);
  };

  const card = (
    <CardContent>
      {/* {is_domestic} */}
      <Typography gutterBottom variant="h6" component="div">
        <VendorInstructions>
          <InfoIcon color="primary" /> {"\u00A0"}
          {"\u00A0"}Instructions
        </VendorInstructions>
      </Typography>
      <br />

      <VendorListHolder>
        <li>
          Enter statutory information which includes company nature,
          constitution and GST registration type.
        </li>
        <li>
          Legal name is auto-populated as per the information on the PAN
          database. It is not editable for vendors.
        </li>
        <li>
          Enter all the mandatory fields before proceeding to enter location
          information.
        </li>
        <li className="text-danger">Documents size cannot exceed 500kb</li>
      </VendorListHolder>
    </CardContent>
  );

  const statutory_information = (
    <CardContent>
      <Container fixed>
        <Grid>
          <VendorCardTitle>Statutory information</VendorCardTitle>
          <UserInfoDiv>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <VendorCardLabel>Company name</VendorCardLabel>
                <VendorDisabledFields>
                  {value.company_name}
                </VendorDisabledFields>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <VendorCardLabel>Company email</VendorCardLabel>

                <VendorDisabledFields>
                  {value.contact_person_email}
                </VendorDisabledFields>
              </Grid>
              <Grid item xs={12} md={4}>
                <VendorCardLabel>
                  Permanent account number (PAN)
                </VendorCardLabel>

                <VendorDisabledFields>
                  {typeof value.PAN_number !== undefined &&
                  value.legal_name !== null
                    ? `${value.PAN_number}`
                    : `International`}
                </VendorDisabledFields>
              </Grid>
            </Grid>
          </UserInfoDiv>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <VendorCardLabel>
              Legal name <Super>*</Super>
            </VendorCardLabel>

            <VendorDisabledFields
              style={{
                borderBottom: "1px solid lightgray",
                width: "100%",
                padding: " 0 0 5px 0",
                paddingTop: "5px",
              }}
            >
              {typeof value.legal_name !== undefined &&
              value.legal_name !== null
                ? `${value.legal_name}`
                : `Not Applicable`}
            </VendorDisabledFields>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="standard"
              style={{ width: "100%" }}
              error={Boolean(errors?.company_constitution)}
            >
              <VendorCardLabel>
                Company constitution <Super>*</Super>
              </VendorCardLabel>
              <Select
                sx={{ fontFamily: "var(--font-family-reg)", fontSize: "14px" }}
                displayEmpty
                defaultValue=""
                IconComponent={() => (
                  <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                )}
                labelId="demo-simple-select-standard-label"
                id="company_constitution"
                label="Company consistution (As per GST certificate)"
                name="company_constitution"
                {...register("company_constitution", {
                  required: "Please select constitution",
                })}
              >
                <MenuItem value="">
                  <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                    Select constitution
                  </span>
                </MenuItem>

                <MenuItem value="Association of Persons (AOP)">
                  <SelectSpan>Association of Persons (AOP)</SelectSpan>
                </MenuItem>
                <MenuItem value="Partnership Firm">
                  <SelectSpan>Partnership Firm</SelectSpan>
                </MenuItem>
                <MenuItem value="Individual/One person Company">
                  <SelectSpan>Individual/One person Company</SelectSpan>
                </MenuItem>
                <MenuItem value="Individual">
                  <SelectSpan>Individual</SelectSpan>
                </MenuItem>
                <MenuItem value="Joint Venture (JV)">
                  <SelectSpan>Joint Venture (JV)</SelectSpan>
                </MenuItem>
                <MenuItem value="Limited Liability Partnership (LLP)">
                  <SelectSpan>Limited Liability Partnership (LLP)</SelectSpan>
                </MenuItem>
                <MenuItem value="Limited">
                  <SelectSpan>Limited</SelectSpan>
                </MenuItem>
                <MenuItem value="Limited Company/Listed company">
                  <SelectSpan>Limited Company/Listed company</SelectSpan>
                </MenuItem>
                <MenuItem value="Non banking finance company/ Banking company">
                  <SelectSpan>
                    Non banking finance company/ Banking company
                  </SelectSpan>
                </MenuItem>
                <MenuItem value="Proprietary Firm">
                  <SelectSpan>Proprietary Firm</SelectSpan>
                </MenuItem>
                <MenuItem value="Private Limited Company">
                  <SelectSpan>Private Limited Company</SelectSpan>
                </MenuItem>
                <MenuItem value="Firm">
                  <SelectSpan>Firm</SelectSpan>
                </MenuItem>
                <MenuItem value="Private Limited">
                  <SelectSpan>Private Limited</SelectSpan>
                </MenuItem>
                <MenuItem value="Co-operative society">
                  <SelectSpan>Co-operative society</SelectSpan>
                </MenuItem>
                <MenuItem value="Foreign Company">
                  <SelectSpan>Foreign Company</SelectSpan>
                </MenuItem>
                <MenuItem value="Hindu Undivided Family">
                  <SelectSpan>Hindu Undivided Family</SelectSpan>
                </MenuItem>
                <MenuItem value="Government Organisation">
                  <SelectSpan>Government Organisation</SelectSpan>
                </MenuItem>
                <MenuItem value="Government Company">
                  <SelectSpan>Government Company</SelectSpan>
                </MenuItem>
              </Select>
              <FormHelperText>
                {errors.company_constitution?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="standard"
              style={{ width: "100%" }}
              error={Boolean(errors?.company_nature)}
            >
              <VendorCardLabel>
                Company nature<Super> *</Super>
              </VendorCardLabel>
              <Select
                sx={{ fontFamily: "var(--font-family-reg)", fontSize: "14px" }}
                displayEmpty
                defaultValue=""
                IconComponent={() => (
                  <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                )}
                labelId="demo-simple-select-standard-label"
                id="company_nature"
                label="company_nature"
                name="company_nature"
                {...register("company_nature", {
                  required: "Please select company nature",
                })}
                error={Boolean(errors?.company_nature)}
              >
                <MenuItem value="">
                  <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                    Select company nature
                  </span>
                </MenuItem>

                <MenuItem value="dta">
                  <SelectSpan>Domestic Tariff Area (DTA)</SelectSpan>
                </MenuItem>
                <MenuItem value="domestic">
                  <SelectSpan> Indian Company</SelectSpan>
                </MenuItem>
                <MenuItem value="Foreign Company">
                  <SelectSpan>Foreign Company (Outside India)</SelectSpan>
                </MenuItem>
                <MenuItem value="sez">
                  <SelectSpan>Special Economic Zone (SEZ)</SelectSpan>
                </MenuItem>
                <MenuItem value="stpi">
                  <SelectSpan>
                    Software Technology Parks of India (STPI)
                  </SelectSpan>
                </MenuItem>
              </Select>
              <FormHelperText>{errors.company_nature?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="standard"
              style={{ width: "100%" }}
              error={Boolean(errors?.affirmative_action_category)}
            >
              <VendorCardLabel>Affirmative action category</VendorCardLabel>
              <Select
                sx={{ fontFamily: "var(--font-family-reg)", fontSize: "14px" }}
                displayEmpty
                defaultValue=""
                IconComponent={() => (
                  <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                )}
                labelId="demo-simple-select-standard-label"
                id="affirmative_action_category"
                label="Affirmative action category"
                name="affirmative_action_category"
                {...register("affirmative_action_category", {
                  required: false,
                })}
                error={Boolean(errors?.affirmative_action_category)}
              >
                <MenuItem value="">
                  <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                    Select category
                  </span>
                </MenuItem>
                <MenuItem value="open">
                  <SelectSpan>OPEN</SelectSpan>
                </MenuItem>
                <MenuItem value="obc">
                  <SelectSpan>OBC</SelectSpan>
                </MenuItem>
                <MenuItem value="sc">
                  <SelectSpan>SC/ST</SelectSpan>
                </MenuItem>
                <MenuItem value="other">
                  <SelectSpan>OTHERS</SelectSpan>
                </MenuItem>
                <MenuItem value="dnd">
                  <SelectSpan>Don't wish to declare</SelectSpan>
                </MenuItem>
              </Select>
              <FormHelperText>
                {errors.affirmative_action_category?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          {is_domestic && (
            <Grid item xs={12} sm={6} md={4}>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.registration_type)}
              >
                <VendorCardLabel>
                  Registration type (GST) <Super>*</Super>
                </VendorCardLabel>
                <Select
                  sx={{
                    fontFamily: "var(--font-family-reg)",
                    fontSize: "14px",
                  }}
                  defaultValue=""
                  displayEmpty={true}
                  IconComponent={() => (
                    <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                  )}
                  labelId="demo-simple-select-standard-label"
                  id="registration_type"
                  label="Registration_type"
                  name="registration_type"
                  {...register("registration_type", {
                    required: "Please select registration type",
                  })}
                  error={Boolean(errors?.registration_type)}
                  onChange={(e) => handleregistrationChange(e)}
                >
                  <MenuItem value="">
                    <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                      Select registration type
                    </span>
                  </MenuItem>
                  <MenuItem value="regular">
                    <SelectSpan>Regular</SelectSpan>
                  </MenuItem>
                  <MenuItem value="composition">
                    <SelectSpan>Composition</SelectSpan>
                  </MenuItem>
                  <MenuItem value="unregistered">
                    <SelectSpan>Not Applicable</SelectSpan>
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errors.registration_type?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <VendorCardLabel>Tax Identification number (TIN)</VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              InputProps={{
                disableUnderline: false,
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              placeholder="Do not enter GSTIN here"
              size="small"
              variant="standard"
              style={{ width: "100%", paddingTop: "3px" }}
              name="tax_identification_number"
              {...register("tax_identification_number", { required: false })}
            />
          </Grid>
          {/* </Grid>
          <br />
          <Grid container spacing={3}> */}
          <Grid item xs={12} sm={6} md={4}>
            <VendorCardLabel>Annual turnover (in Million)</VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              InputProps={{
                disableUnderline: false,
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              size="small"
              variant="standard"
              style={{
                width: "100%",
                paddingTop: "3px",
                borderBottom: "1px solid lightgray",
              }}
              name="annual_turnover"
              placeholder="Enter annual turnover"
              {...register("annual_turnover", {
                pattern: {
                  value: /^-?\d*(\.\d{1,2})?$/,
                  message: "Invalid number format",
                },
              })}
              error={Boolean(errors?.annual_turnover)}
              helperText={errors.annual_turnover?.message}
              onChange={convertToMillions}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VendorCardLabel>Annual turnover (in Crore)</VendorCardLabel>
            <VendorDisabledFields>{annualCr}</VendorDisabledFields>
          </Grid>
        </Grid>
      </Container>
    </CardContent>
  );

  const msme_information = (
    <CardContent>
      <Container fixed>
        <VendorCardTitle>MSME information</VendorCardTitle>
        <VRSubTitle>For tax purposes</VRSubTitle>
        <Grid container spacing={1}>
          {is_domestic && (
            <Grid item xs={12} md={4}>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.msme_register)}
              >
                <VendorCardLabel>
                  Are you MSME registered? <Super>*</Super>
                </VendorCardLabel>
                <Select
                  sx={{
                    fontFamily: "var(--font-family-reg)",
                    fontSize: "14px",
                  }}
                  defaultValue=""
                  displayEmpty={true}
                  IconComponent={() => (
                    <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                  )}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Are you MSME registered?"
                  name="msme_register"
                  {...register("msme_register", {
                    required: "Please select MSME category",
                  })}
                  onChange={(e) => handlemsme(e.target.value)}
                >
                  <MenuItem value="">
                    <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                      Select value
                    </span>
                  </MenuItem>
                  <MenuItem value="Applicable">
                    <SelectSpan>Applicable</SelectSpan>
                  </MenuItem>
                  <MenuItem value="others">
                    <SelectSpan>Not Applicable</SelectSpan>
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errors.msme_registered?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

          {mSME !== "others" && mSME !== "" ? (
            <>
              <Grid item xs={12} md={4}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    MSME registration number <Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    name="msme_registration_number"
                    placeholder="Enter MSME registration number"
                    {...register("msme_registration_number", {
                      required: false,
                    })}
                    value={msmenum || ""}
                    onChange={(e) => setMsmenum(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <span
                      variant="outlined"
                      component="span"
                      style={{ width: "50%" }}
                    >
                      <DocumentLabels>
                        Upload MSME copy<Super>*</Super>
                      </DocumentLabels>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="standard"
                      style={{ width: "100%" }}
                      error={Boolean(errors?.msme)}
                    >
                      {msmeDoc !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#4945FF",
                              fontSize: "13px",
                              fontFamily: "var(--font-family-reg)",
                            }}
                          >
                            <Chip
                              style={{
                                width: "150px",
                                color: "#4945FF",
                                margin: "5px",
                                borderRadius: "5px",
                                border: "1px solid #D9D8FF",
                                background: "#F0F0FF",
                              }}
                              label={msmeDoc.name}
                              value={msmeDoc.name}
                              name="chips"
                            />
                            <HighlightOffIcon
                              sx={{ color: "#4945FF", paddingBottom: "2px" }}
                              fontSize="medium"
                              onClick={(e) => setmsmeDoc("")}
                            />
                          </span>
                        </>
                      ) : (
                        <label htmlFor="msme">
                          <Input
                            {...register("msme", {
                              required: "Please upload MSME copy",
                            })}
                            accept="*"
                            id="msme"
                            onChange={(e) => setmsmeDoc(e.target.files[0])}
                            type="file"
                          />
                          <StyledOutlineButton
                            variant="outlined"
                            component="span"
                          >
                            Upload
                          </StyledOutlineButton>
                        </label>
                      )}
                      {msmeDoc === "" ? (
                        <FormHelperText>{errors.msme?.message}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </CardContent>
  );

  const documents = (
    <CardContent>
      <Container fixed>
        <VendorCardTitle style={{ marginBottom: "3px" }}>
          Documents
        </VendorCardTitle>
        <VRSubTitle>
          <span>
            <InfoIcon color="primary" />
          </span>
          {"\u00A0"}
          {"\u00A0"}
          TDS may be deducted at higher rates in case of incorrect or missing
          PAN information as per IT rules
        </VRSubTitle>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {is_domestic === true ? (
            <>
              <Grid item xs={6}>
                <span
                  variant="outlined"
                  component="span"
                  style={{ width: "50%" }}
                >
                  <DocumentLabels>
                    Upload PAN copy<Super>*</Super>
                  </DocumentLabels>
                </span>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="standard"
                  style={{ width: "100%" }}
                  error={Boolean(errors?.pan)}
                >
                  {panDoc !== "" ? (
                    <>
                      <span
                        style={{
                          color: "#4945FF",
                          fontSize: "13px",
                          fontFamily: "var(--font-family-reg)",
                        }}
                      >
                        <Chip
                          style={{
                            width: "150px",
                            color: "#4945FF",
                            margin: "5px",
                            borderRadius: "5px",
                            border: "1px solid #D9D8FF",
                            background: "#F0F0FF",
                          }}
                          label={panDoc.name}
                          value={panDoc.name}
                          name="chips"
                        />
                        <HighlightOffIcon
                          sx={{ color: "#4945FF", paddingBottom: "2px" }}
                          fontSize="medium"
                          onClick={(e) => setPanDoc("")}
                        />
                      </span>
                    </>
                  ) : (
                    <label htmlFor="pan">
                      <Input
                        {...register("pan", {
                          required: "Please upload PAN copy",
                        })}
                        accept="*"
                        id="pan"
                        onChange={(e) => setPanDoc(e.target.files[0])}
                        type="file"
                      />
                      <StyledOutlineButton variant="outlined" component="span">
                        Upload
                      </StyledOutlineButton>
                    </label>
                  )}
                  {panDoc === "" ? (
                    <FormHelperText>{errors.pan?.message}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
        <br />

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span variant="outlined" component="span" style={{ width: "50%" }}>
              <DocumentLabels>
                {vendorCompany.id === 3 ? "VAD" : "Vendor information form"}
                {vendorCompany.id === 1 || vendorCompany.id === 5 ? (
                  ""
                ) : (
                  <Super>*</Super>
                )}
              </DocumentLabels>
              {vendorCompany.id === 2 ? (
                <CustomDocumentLink
                  style={{ fontSize: "0.8rem" }}
                  href={VRF_vishakha}
                >
                  <FileDownload fontSize="small" color="primary" />
                  {"\u00A0"} Download Form
                </CustomDocumentLink>
              ) : (
                ""
              )}
            </span>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              style={{ width: "100%" }}
              error={Boolean(errors?.dealerName)}
            >
              {dealer !== "" ? (
                <>
                  <span
                    style={{
                      color: "#4945FF",
                      fontSize: "13px",
                      fontFamily: "var(--font-family-reg)",
                    }}
                  >
                    <Chip
                      style={{
                        width: "150px",
                        color: "#4945FF",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #D9D8FF",
                        background: "#F0F0FF",
                      }}
                      label={dealer.name}
                      value={dealer.name}
                      name="chips"
                    />
                    <HighlightOffIcon
                      sx={{ color: "#4945FF", paddingBottom: "2px" }}
                      fontSize="medium"
                      onClick={(e) => setDealer("")}
                    />
                  </span>
                </>
              ) : (
                <label htmlFor="dealer">
                  <Input
                    {...register("dealerName", {
                      required:
                        vendorCompany.id === 1 || vendorCompany.id === 5
                          ? false
                          : "Please upload vendor form copy",
                    })}
                    accept="*"
                    id="dealer"
                    name="dealer"
                    onChange={(e) => setDealer(e.target.files[0])}
                    type="file"
                  />
                  <StyledOutlineButton variant="outlined" component="span">
                    Upload
                  </StyledOutlineButton>
                  <span>{dealer.name}</span>
                </label>
              )}
              {dealer === "" ? (
                <FormHelperText>{errors.dealerName?.message}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        {/* <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <span
                variant="outlined"
                component="span"
                style={{ width: "50%" }}
              >
                <DocumentLabels>
                  Upload dealer authorization certificate
                </DocumentLabels>
              </span>
            </Grid>
            <Grid item xs={6}>
              {dealer !== "" ? (
                <>
                  <span
                    style={{
                      color: "#4945FF",
                      fontSize: "13px",
                      fontFamily: "var(--font-family-reg)",
                    }}
                  >
                    <Chip
                      style={{
                        width: "150px",
                        color: "#4945FF",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #D9D8FF",
                        background: "#F0F0FF",
                      }}
                      label={dealer.name}
                      value={dealer.name}
                      name="chips"
                    />
                    <HighlightOffIcon
                      sx={{ color: "#4945FF", paddingBottom: "2px" }}
                      fontSize="medium"
                      onClick={(e) => setDealer("")}
                    />
                  </span>
                </>
              ) : (
                <label htmlFor="dealer">
                  <Input
                    accept="*"
                    id="dealer"
                    name="dealer"
                    onChange={(e) => setDealer(e.target.files[0])}
                    type="file"
                  />
                  <StyledOutlineButton variant="outlined" component="span">
                    Upload
                  </StyledOutlineButton>
                  <span>{dealer.name}</span>
                </label>
              )}
            </Grid>
          </Grid> */}

        <br />
        {vendorCompany.id === 2 ? (
          ""
        ) : (
          <>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <span
                  variant="outlined"
                  component="span"
                  style={{ width: "50%" }}
                >
                  <DocumentLabels> Upload TIN certificate</DocumentLabels>
                </span>
              </Grid>
              <Grid item xs={6}>
                {tin !== "" ? (
                  <>
                    <span
                      style={{
                        color: "#4945FF",
                        fontSize: "13px",
                        fontFamily: "var(--font-family-reg)",
                      }}
                    >
                      <Chip
                        style={{
                          width: "150px",
                          color: "#4945FF",
                          margin: "5px",
                          borderRadius: "5px",
                          border: "1px solid #D9D8FF",
                          background: "#F0F0FF",
                        }}
                        label={tin.name}
                        value={tin.name}
                        name="chips"
                      />
                      <HighlightOffIcon
                        sx={{ color: "#4945FF", paddingBottom: "2px" }}
                        fontSize="medium"
                        onClick={(e) => setTin("")}
                      />
                    </span>
                  </>
                ) : (
                  <label htmlFor="tin_doc">
                    <Input
                      accept="*"
                      id="tin_doc"
                      name="tin_doc"
                      onChange={(e) => setTin(e.target.files[0])}
                      type="file"
                    />
                    <StyledOutlineButton variant="outlined" component="span">
                      Upload
                    </StyledOutlineButton>
                    <span>{tin.name}</span>
                  </label>
                )}
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <span
                  variant="outlined"
                  component="span"
                  style={{ width: "50%" }}
                >
                  <DocumentLabels>
                    Upload ISO, RSDO and any additional certificate
                  </DocumentLabels>
                </span>
              </Grid>
              <Grid item xs={6}>
                {iso !== "" ? (
                  <>
                    <span
                      style={{
                        color: "#4945FF",
                        fontSize: "13px",
                        fontFamily: "var(--font-family-reg)",
                      }}
                    >
                      <Chip
                        style={{
                          width: "150px",
                          color: "#4945FF",
                          margin: "5px",
                          borderRadius: "5px",
                          border: "1px solid #D9D8FF",
                          background: "#F0F0FF",
                        }}
                        label={iso.name}
                        value={iso.name}
                        name="chips"
                      />
                      <HighlightOffIcon
                        sx={{ color: "#4945FF", paddingBottom: "2px" }}
                        fontSize="medium"
                        onClick={(e) => setIso("")}
                      />
                    </span>
                  </>
                ) : (
                  <label htmlFor="iso_doc">
                    <Input
                      accept="*"
                      id="iso_doc"
                      name="iso_doc"
                      onChange={(e) => setIso(e.target.files[0])}
                      type="file"
                    />
                    <StyledOutlineButton variant="outlined" component="span">
                      Upload
                    </StyledOutlineButton>
                    <span>{iso.name}</span>
                  </label>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {newDocs.map((item, index) => (
          <>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              key={index}
            >
              <>
                <Grid item xs={6}>
                  <span
                    variant="outlined"
                    component="span"
                    style={{ width: "50%" }}
                  >
                    <DocumentLabels style={{ marginTop: "10px" }}>
                      Upload Any Documents
                    </DocumentLabels>
                  </span>
                </Grid>
                {anyDocs[index] !== "" && anyDocs[index] !== undefined ? (
                  <>
                    <Grid item xs={6}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={anyDocs[index].name}
                            value={anyDocs[index].name}
                            name="chips"
                          />
                          <HighlightOffIcon
                            sx={{ color: "#4945FF", paddingBottom: "2px" }}
                            fontSize="medium"
                            onClick={(e) => handleclearDoc(e, index)}
                          />
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      {index === 0 ? (
                        <CustomButton
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={handleOnClick}
                          className="invoiceErrbutn"
                        >
                          Add Document
                        </CustomButton>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={3}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <label>
                          <Input
                            accept="*"
                            id={`anyDocs${index}`}
                            onChange={(e) => handlechangeDoc(e, index)}
                            type="file"
                          />
                          <StyledOutlineButton
                            variant="outlined"
                            component="span"
                          >
                            Upload
                          </StyledOutlineButton>
                        </label>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      {index === 0 ? (
                        <CustomButton
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={handleOnClick}
                          className="invoiceErrbutn"
                          style={{ marginTop: "10px " }}
                        >
                          Add Document
                        </CustomButton>
                      ) : (
                        <CustomButton
                          className="invoiceErrbutn"
                          color="error"
                          variant="outlined"
                          size="small"
                          onClick={(e) => handleRemove(e, index)}
                          style={{ marginTop: "10px " }}
                        >
                          Remove
                        </CustomButton>
                      )}
                    </Grid>
                  </>
                )}
              </>
            </Grid>
          </>
        ))}
      </Container>
    </CardContent>
  );

  const additional_info = (
    <CardContent>
      <Container fixed>
        <VendorCardTitle>Additional information</VendorCardTitle>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={9}>
            <VRSubTitle>
              Areas of business interest. Select all that apply and wait for the
              HSN/SAC code to appear below
            </VRSubTitle>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <VRSubTitle style={{ textAlign: "right" }}>
              <a
                rel="noopener noreferrer"
                href={`${vendorCompany?.domain_url}/api/hsn_codes`}
                style={{
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                  color: "#4059e5",
                }}
                target="_blank"
              >
                Download Codesheet
              </a>
            </VRSubTitle>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} xs={12}>
            <AdditionalDetailsCard>
              <CardContent style={{ overflow: "auto", height: "272px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  // style={{ marginTop: "36px" }}
                >
                  <Grid
                    style={checkedType === "2" ? radioStyles.selectRadio : null}
                  >
                    <CustomFormSelection
                      value="2"
                      control={<Radio style={{ visibility: "hidden" }} />}
                      label="Goods"
                      style={{
                        textAlign: "-23px",
                      }}
                      onChange={(e) => handleadditionalChange(e, "2")}
                      className="goods"
                    />

                    {checkedType === "2" ? (
                      <ArrowRightIcon
                        style={{
                          textAlign: "center",
                          float: "right",
                          marginTop: "7px",
                          marginLeft: "-32px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    style={checkedType === "1" ? radioStyles.selectRadio : null}
                  >
                    <CustomFormSelection
                      value="1"
                      control={<Radio style={{ visibility: "hidden" }} />}
                      label="Services"
                      /*  style={{ textAlign: "-25px" }} */
                      onChange={(e) => handleadditionalChange(e, "1")}
                      className="goods"
                    />
                    <span
                      style={{
                        textAlign: "center",
                        float: "right",
                        marginTop: "7px",
                        marginLeft: "-32px",
                      }}
                    >
                      {checkedType === "1" ? <ArrowRightIcon /> : ""}
                    </span>
                  </Grid>

                  {vendorCompany?.id === 2 ? (
                    <Grid
                      style={
                        checkedType === "3" ? radioStyles.selectRadio : null
                      }
                    >
                      <CustomFormSelection
                        value="3"
                        control={<Radio style={{ visibility: "hidden" }} />}
                        label="Item"
                        /*  style={{ textAlign: "-25px" }} */
                        onChange={(e) => handleadditionalChange(e, "3")}
                        className="goods"
                      />
                      <span
                        style={{
                          textAlign: "center",
                          float: "right",
                          marginTop: "7px",
                          marginLeft: "-32px",
                        }}
                      >
                        {checkedType === "3" ? <ArrowRightIcon /> : ""}
                      </span>
                    </Grid>
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </CardContent>
            </AdditionalDetailsCard>
          </Grid>
          {isLoading ? (
            <Grid item md={3} xs={12}>
              <AdditionalDetailsCard>
                <CardContent style={{ overflow: "auto", height: "272px" }}>
                  <Skeleton count={7} />
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : code_filtered ? (
            <Grid item md={3} xs={12} style={{ display: catdisplay }}>
              <AdditionalDetailsCard>
                <div style={{ height: "50px", backgroundColor: " #F7F9FC" }}>
                  <TextField
                    id="Search"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    className="search_for_add_info"
                    variant="standard"
                    onChange={(e) => handleSearchChange(e)}
                    style={{ fontSize: "12px !important" }}
                    placeholder="Search by description"
                  />
                </div>
                <CardContent
                  style={{
                    paddingTop: "5px",
                    overflow: "auto",
                    height: "222px",
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {code_filtered.length ? (
                      code_filtered.map((item, index) => (
                        <Grid
                          key={index}
                          style={
                            subCategory === `${item.category}`
                              ? radioStyles1.selectRadio
                              : null
                          }
                        >
                          <Tooltip
                            title={item.categortDescription}
                            placement="right"
                          >
                            <CustomFormControl
                              style={{
                                fontSize: "12px",
                              }}
                              label={item.categortDescription}
                              value={item.category}
                              control={
                                <Radio style={{ visibility: "hidden" }} />
                              }
                              onChange={(e) =>
                                handlecategoryChange(e, `${item.category}`)
                              }
                              className="goods1"
                            />
                          </Tooltip>
                        </Grid>
                      ))
                    ) : (
                      <VendorCardLabel>
                        <br />
                        <InfoIcon
                          sx={{
                            fill: "grey",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                        {"There are no categeories to display"}
                      </VendorCardLabel>
                    )}
                  </RadioGroup>
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : (
            ""
          )}

          {isLoadingsub ? (
            <Grid item md={3} xs={12}>
              <AdditionalDetailsCard>
                <CardContent style={{ overflow: "auto", height: "272px" }}>
                  <Skeleton count={7} />
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : sub_filtered ? (
            <Grid item md={3} xs={12} style={{ display: subcatdisplay }}>
              <AdditionalDetailsCard>
                <div style={{ height: "50px", backgroundColor: " #F7F9FC" }}>
                  <TextField
                    id="Search"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    className="search_for_add_info"
                    variant="standard"
                    onChange={(e) => setSearchsub(e.target.value)}
                    style={{ fontSize: "12px !important" }}
                    placeholder="Search by code"
                  />
                </div>
                <CardContent
                  style={{
                    paddingTop: "5px",
                    overflow: "auto",
                    height: "222px",
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {sub_filtered.length ? (
                      sub_filtered.map((item, index) => (
                        <Grid
                          key={index}
                          style={
                            itemcodes === `${item.subCategory}`
                              ? radioStyles1.selectRadio
                              : null
                          }
                        >
                          <Tooltip
                            title={item.subCategoryDescription}
                            placement="right"
                          >
                            <CustomFormControl
                              style={{
                                fontSize: "12px",
                              }}
                              label={
                                item.subCategory +
                                " - " +
                                item.subCategoryDescription
                              }
                              value={item.subCategory}
                              control={
                                <Radio style={{ visibility: "hidden" }} />
                              }
                              onChange={(e) =>
                                handlesubcategoryChange(
                                  e,
                                  `${item.subCategory}`
                                )
                              }
                              className="goods1"
                            />
                          </Tooltip>
                        </Grid>
                      ))
                    ) : (
                      <VendorCardLabel>
                        <br />
                        <InfoIcon
                          sx={{
                            fill: "grey",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                        {"There are no categeories to display"}
                      </VendorCardLabel>
                    )}
                  </RadioGroup>
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : (
            ""
          )}

          {isLoadingitem ? (
            <Grid item md={3} xs={12}>
              <AdditionalDetailsCard>
                <CardContent style={{ overflow: "auto", height: "272px" }}>
                  <Skeleton count={7} />
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : item_filtered ? (
            <Grid item md={3} xs={12} style={{ display: itemcatdisplay }}>
              <AdditionalDetailsCard>
                <div style={{ height: "50px", backgroundColor: " #F7F9FC" }}>
                  <TextField
                    id="Search"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    className="search_for_add_info"
                    variant="standard"
                    onChange={(e) => setSearchitem(e.target.value)}
                    style={{ fontSize: "12px !important" }}
                    placeholder="Search by code"
                  />
                </div>
                <CardContent
                  style={{
                    paddingTop: "5px",
                    overflow: "auto",
                    height: "222px",
                  }}
                >
                  {item_filtered.length ? (
                    item_filtered.map((item, index) => (
                      <FormGroup
                        key={item.id}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                      >
                        <Grid key={item.id}>
                          <Tooltip
                            title={item.itemDescription}
                            placement="bottom"
                          >
                            <CustomFormControl
                              multiple
                              key={item.id}
                              style={{ fontSize: "12px" }}
                              value={item.item}
                              control={
                                <Checkbox
                                  sx={{
                                    color: "lightgray",
                                    "&.Mui-checked": {
                                      color: "#4059e5",
                                    },
                                  }}
                                  checked={personName.includes(`${item.item}`)}
                                />
                              }
                              label={item.item + " - " + item.itemDescription}
                              className="itemName"
                              onChange={(e) =>
                                handleCheckChange(e, `${item.item}`)
                              }
                            />
                          </Tooltip>
                        </Grid>
                      </FormGroup>
                    ))
                  ) : (
                    <VendorCardLabel>
                      <br />
                      <InfoIcon
                        sx={{
                          fill: "grey",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                      {"There are no sub categeories to display"}
                    </VendorCardLabel>
                  )}
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : (
            ""
          )}
          {isLoadingMasteritem ? (
            <Grid item md={9} xs={12}>
              <AdditionalDetailsCard>
                <CardContent style={{ overflow: "auto", height: "272px" }}>
                  <Skeleton count={7} />
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : master_item_filtered ? (
            <Grid item md={9} xs={12} style={{ display: mastercatdisplay }}>
              <AdditionalDetailsCard>
                <div style={{ height: "50px", backgroundColor: " #F7F9FC" }}>
                  <TextField
                    id="Search"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    className="search_for_add_info"
                    variant="standard"
                    onChange={(e) => setSearchMasteritem(e.target.value)}
                    style={{ fontSize: "12px !important" }}
                    placeholder="Search by code"
                  />
                </div>
                <CardContent
                  style={{
                    paddingTop: "5px",
                    overflow: "auto",
                    height: "222px",
                  }}
                >
                  {master_item_filtered.length ? (
                    master_item_filtered.map((item, index) => (
                      <FormGroup
                        key={item.id}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                      >
                        <Grid key={item.id}>
                          <Tooltip
                            title={item?.MaterialCode}
                            placement="bottom"
                          >
                            <CustomFormControl
                              multiple
                              key={item.id}
                              style={{ fontSize: "12px" }}
                              value={item["MaterialCode"]}
                              control={
                                <Checkbox
                                  sx={{
                                    color: "lightgray",
                                    "&.Mui-checked": {
                                      color: "#4059e5",
                                    },
                                  }}
                                  checked={personName.includes(
                                    `${item.MaterialCode}`
                                  )}
                                />
                              }
                              label={
                                item["MaterialCode"] +
                                " - " +
                                item["Description"]
                              }
                              className="itemName"
                              onChange={(e) =>
                                handleCheckChange(e, `${item["MaterialCode"]}`)
                              }
                            />
                          </Tooltip>
                        </Grid>
                      </FormGroup>
                    ))
                  ) : (
                    <VendorCardLabel>
                      <br />
                      <InfoIcon
                        sx={{
                          fill: "grey",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                      {"There are no sub categeories to display"}
                    </VendorCardLabel>
                  )}
                </CardContent>
              </AdditionalDetailsCard>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <br />
        <VendorCardTitle style={{ paddingBottom: "0px" }}>
          Selected HSN/SAC Codes
        </VendorCardTitle>
        <FormControl>
          <Stack direction="row" spacing={4}>
            <Grid item xs={2}>
              <VendorCardBlankTitle
                style={{ marginTop: "0", marginBottom: "10px" }}
              >
                {userinfo.codes.length ? "" : "No codes selected."}
              </VendorCardBlankTitle>

              {userinfo.codes.map((item, index) =>
                item !== "null" ? (
                  <Chip
                    key={index}
                    style={{
                      color: "#4945FF",
                      margin: "0 5px 10px 0 ",
                      borderRadius: "5px",
                      border: "1px solid #D9D8FF",
                      background: "#F0F0FF",
                    }}
                    label={item}
                    value={item}
                    name="chips"
                    onDelete={handleDelete(item)}
                    deleteIcon={
                      <Clear sx={{ height: "18px", width: "18px" }} />
                    }
                  />
                ) : (
                  ""
                )
              )}
            </Grid>
          </Stack>
        </FormControl>
        <Grid>
          <VendorCardLabel>Additional business areas</VendorCardLabel>
          <TextField
            sx={{
              input: { fontSize: "14px", fontFamily: "var(--font-family-reg)" },
            }}
            id="standard-basic"
            placeholder="Enter interest areas"
            variant="standard"
            style={{ width: "100%" }}
            name="business_interest_areas"
            {...register("business_interest_areas", {
              required: false,
            })}
          />
        </Grid>
        <br />
        {is_domestic === true ? (
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "lightgray",
                  "&.Mui-checked": {
                    color: "#4059e5",
                  },
                }}
                onChange={handleChange}
                name="pan_declaration"
              />
            }
            label={
              <CheckBoxLabel>
                I confirm that the PAN I entered above should be used for all
                TDS deductions made for our company payments
              </CheckBoxLabel>
            }
          />
        ) : (
          ""
        )}
      </Container>
    </CardContent>
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          <div className="stepsDiv">
            {steps.map((label, index) => {
              const stepProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <>
                  {activeStep === 0 ? (
                    <div
                      key={index}
                      className={
                        index === 0 ? "stepcolm fstone" : "stepcolm secone"
                      }
                    >
                      <div key={index}>
                        {index === 0 ? (
                          <span>{"1. "}</span>
                        ) : (
                          <span>{"2. "}</span>
                        )}
                        {label}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={
                        index === 0 ? "stepcolm fstone_1" : "stepcolm secone_1"
                      }
                    >
                      <div key={index}>
                        {index === 0 ? (
                          <span>
                            <CheckCircleIcon className="checkedCircle" />
                          </span>
                        ) : (
                          <span>{"2. "}</span>
                        )}
                        {label}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
            <br />
            {activeStep === 0 ? (
              <>
                <form
                  onSubmit={handleSubmit(handleNext)}
                  encType="multipart/form-data"
                >
                  <StyledCard>{card}</StyledCard>

                  <StyledCard>{statutory_information}</StyledCard>

                  {is_domestic && <StyledCard>{msme_information}</StyledCard>}

                  <StyledCard>{documents}</StyledCard>

                  <StyledCard>{additional_info}</StyledCard>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1, display: "none" }}
                    >
                      Back
                    </Button>

                    <Box sx={{ flex: "1 1 auto" }} />
                    <LoadingButton
                      sx={{
                        textTransform: "none",
                        background: "var(--button-color)",
                        width: "200px",
                        height: "40px",
                      }}
                      color="success"
                      variant="contained"
                      disabled={
                        is_domestic !== true
                          ? false
                          : checkedPan === false
                          ? true
                          : false
                      }
                      type="submit"
                      loading={loading}
                      loadingPosition="start"
                    >
                      {activeStep === steps.length - 1
                        ? bank_info
                          ? "Save & Next"
                          : "Finish"
                        : "Save & Next"}
                    </LoadingButton>
                  </Box>
                </form>
              </>
            ) : (
              <>
                <BankReg
                  activeStep={activeStep}
                  handleBack={handleBack}
                  checkedPan={checkedPan}
                  steps={steps}
                  registrationtype={registrationtype}
                  clientId={vendorCompany?.id}
                  is_domestic={is_domestic}
                />
              </>
            )}
          </React.Fragment>
        )}
      </Box>
    </>
  );
}

export const StyledCard = materialStyles(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorDisabledFields = styled.div`
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #212134;
`;

export const VendorLabelHolder = styled.div`
  text-align: left;
`;

export const StyledOutlineButton = materialStyles(Button)`
  border: 1px solid var(--button-color);
  color: black;
  height: 40px;
  padding: 8px 30px;
`;

export const StyledFilledButton = materialStyles(Button)`
  background: #1CB697;
  color: #fff;
  height: 40px;
  padding: 8px 30px;
`;

export const AdditionalDetailsCard = materialStyles(Card)`
border: 1px solid #D9D8FF;
background: #F7F9FC;
box-shadow: none;
`;

export const VRSubTitle = styled.div`
  margin-top: -15px;
  margin-bottom: 20px;
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/600 */

  color: #666687;
`;

export const DocumentLabels = styled.div`
  //styleName: 14/Medium;
  font-family: var(--font-family-med);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CheckBoxLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Neutral/900 */

  color: #212134;
`;

export const StyledStep = materialStyles(Step)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const CustomButton = materialStyles(Button)`
 ::hover{
   background-color: #1cb;
   font-size: 16.5px;
   transition: all 0.3ms ease-in;
 }
`;

export const SelectSpan = styled.span`
  font-family: var(--font-family-reg);
  font-size: 14px;
  color: "black";
`;

export const StepsSpan = styled.span`
  font-family: var(--font-family-reg);
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-right: 10px;
  color: #4a4a6a;
`;

export const CustomFormControl = materialStyles(FormControlLabel)`
.css-ahj2mt-MuiTypography-root {
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;


}
`;

export const CustomFormSelection = materialStyles(FormControlLabel)`
.css-ahj2mt-MuiTypography-root {
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
`;
