import { apiUrl, getLocalToken } from "../../apiClient";
import { endpoint } from "../endpoint";
const { createApi, fetchBaseQuery } = require("@reduxjs/toolkit/query/react");

export const vendor_category_api = createApi({
  reducerPath: "vendor_category",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/api`,
    prepareHeaders: (headers) => {
      let token = getLocalToken();
      if (token) {
        headers.set("Authorization", token);
      }
      headers.set = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      return headers;
    },
  }),
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: ({ page, per_page, search }) =>
        `${endpoint.vendor_category}?page=${page}&per_page=${per_page}&search=${
          search || ""
        } `,
      providesTags: ["Category"],
    }),
    addCategory: builder.mutation({
      query: (data) => ({
        url: endpoint.vendor_category,
        method: "POST",
        body: data,
        headers: {
          Authorization: getLocalToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["Category"],
    }),
    getCategorySAPwise: builder.query({
      query: (id) => `${endpoint.vendor_category}/${id}`,
      providesTags: ["Category"],
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useAddCategoryMutation,
  useLazyGetCategorySAPwiseQuery,
} = vendor_category_api;
