import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_pr_granted_users,
  get_pr_pending_users,
  get_pr_revoked_users,
  grant_pr_privilege,
  revoke_pr_privilege,
} from "./userPrivilegesAPI";

const initialState = {
  loading: false,
  prGrantedList: [],
  prPendingList: [],
  prRevokedList: [],
};
const actions = {
  GETPRGRANTEDUSERS: "getPrGrantedUsers/GETPRGRANTEDUSERS",
  GETPRPENDINGUSERS: "getPrPendingUsers/GETPRPENDINGUSERS",
  GETPRREVOKEDUSERS: "getPrRevokedUsers/GETPRREVOKEDUSERS",
  GRANTPRPRIVILEGE: "grantPRPrivilege/GRANTPRPRIVILEGE",
  REVOKEPRPRIVILEGE: "revokePRPrivilege/REVOKEPRPRIVILEGE",
};

export const getPrGrantedUsers = createAsyncThunk(
  actions.GETPRGRANTEDUSERS,
  async (payload) => {
    const response = await get_pr_granted_users(
      payload?.page,
      payload?.per_page
      // payload?.search
    );
    return response;
  }
);

export const getPrPendingUsers = createAsyncThunk(
  actions.GETPRPENDINGUSERS,
  async (payload) => {
    const response = await get_pr_pending_users(
      payload?.page,
      payload?.per_page
      // payload?.search
    );
    return response;
  }
);

export const getPrRevokedUsers = createAsyncThunk(
  actions.GETPRREVOKEDUSERS,
  async (payload) => {
    const response = await get_pr_revoked_users(
      payload?.page,
      payload?.per_page
      // payload?.search
    );
    return response;
  }
);

export const grantPRPrivilege = createAsyncThunk(
  actions.GRANTPRPRIVILEGE,
  async (payload) => {
    const response = await grant_pr_privilege(payload);
    return response;
  }
);

export const revokePRPrivilege = createAsyncThunk(
  actions.REVOKEPRPRIVILEGE,
  async (payload) => {
    const response = await revoke_pr_privilege(payload);
    return response;
  }
);

export const userPrivilegesSlice = createSlice({
  name: "userPrivileges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrGrantedUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrGrantedUsers.fulfilled, (state, action) => {
        const { success, message: msg, data: { data, pagination }, } = action.payload;
        state.loading = false;
        state.prGrantedList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrGrantedUsers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getPrPendingUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrPendingUsers.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.prPendingList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrPendingUsers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getPrRevokedUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrRevokedUsers.fulfilled, (state, action) => {
        const { success, message: msg, data: { data, pagination }, } = action.payload;
        state.loading = false;
        state.prRevokedList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrRevokedUsers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(grantPRPrivilege.pending, (state) => {
        state.loading = true;
      })
      .addCase(grantPRPrivilege.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(grantPRPrivilege.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(revokePRPrivilege.pending, (state) => {
        state.loading = true;
      })
      .addCase(revokePRPrivilege.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(revokePRPrivilege.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
  },
});
export const userPrivilegesActions = userPrivilegesSlice.actions;
export const { resetuserPrivilegesDetails } = userPrivilegesSlice.actions;

export default userPrivilegesSlice.reducer;
