import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  getVendorDetails,
  approveVendor,
  getBanks,
} from "../redux/vendorRegistrationSlice";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { history } from "../../../app/history";
import { StyledCard, VendorCardLabel } from "./StepMain";
import { VendorCardTitle } from "./steps/vendorReg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Tooltip } from "@mui/material";
// import { BankCard } from "../../bankDetails/components/bankAccountsContent";
import { bank_info, dev } from "../../../utilities/helper";
import BankInfoDetails from "../../../common/sharedComponents/BankInfoDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

/* const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}; */

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  background: "#f5f7f9",
}));

export default function ViewvendorDetails() {
  const { vendor_details, getBanksDetails } = useSelector(
    (state) => state.vendorRegistration
  );
  const value = JSON.parse(localStorage.getItem("tata_login_user"));
  const client = JSON.parse(localStorage.getItem("client"));
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState("panel1");
  const [businessexpanded, setBusinessexpanded] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openRej, setOpenRej] = React.useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isPending, setIsPending] = useState(false);
  const [reasonOpen, setReasonOpen] = useState({ isOpen: false, message: "" });
  const [annual, setAnnual] = useState({ IND: "₹0.00 CR", USD: "" });

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const vendor_id = params.id;
  const handleReject = (vendor_id) => {
    let payload = { vendor_id: vendor_id, is_rejected: true };

    if (editorState.getCurrentContent().hasText()) {
      payload["reason"] = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
    } else {
      payload["reason"] = "";
    }
    dispatch(approveVendor(payload)).then((response) => {
      // setOpenRej(false);
      if (response.payload.success === true) {
        if (value?.roles[0] === "officer") {
          history.push("/app/vendor");
        } else {
          history.push("/app/recomm-vcc");
        }
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenReject = () => {
    setOpenRej(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseRej = () => {
    setOpenRej(false);
  };

  const handleExpandClick = (e, index) => {
    // If the clicked index is already expanded, close it, otherwise, expand it
    setBusinessexpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleApprove = (vendor_id) => {
    const payload = { vendor_id: vendor_id, is_approved: true };
    dispatch(approveVendor(payload)).then((response) => {
      setOpen(false);
      if (response.payload.success === true) {
        if (value.roles[0] === "officer") {
          history.push("/app/vendor");
        } else {
          history.push("/app/recomm-vcc");
        }
      }
    });
  };

  const ViewVendordetails = (vendor_id) => {
    dispatch(getVendorDetails(vendor_id)).then((response) => {
      if (
        response?.payload?.data?.is_pending &&
        response?.payload?.data?.status === "Registered"
      ) {
        setIsPending(true);
      }
      const annual_turnover = response?.payload?.data?.annual_turnover;
      setAnnual({
        USD:
          Number(annual_turnover).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }) + " Million",
        IND:
          Number(annual_turnover / 10).toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }) + " CR",
      });
    });
    if (value.roles[0] !== "user") {
      dispatch(getBanks(vendor_id));
    }
  };

  useEffect(() => {
    ViewVendordetails(vendor_id);
    // eslint-disable-next-line
  }, []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const constitutions = {
    "Association of Persons (AOP)": "Association of Persons (AOP)",
    "Partnership Firm": "Partnership Firm",
    "Individual/One person Company": "Individual/One person Company",
    Individual: "Individual",
    "Joint Venture (JV)": "Joint Venture (JV)",
    Limited: "Limited",
    "Limited Liability Partnership (LLP)":
      "Limited Liability Partnership (LLP)",
    "Limited Company/Listed company": "Limited Company/Listed company",
    "Non banking finance company/ Banking company":
      "Non banking finance company/ Banking company",
    "Proprietary Firm": "Proprietary Firm",
    "Private Limited Company": "Private Limited Company",
    Firm: "Firm",
    "Private Limited": "Private Limited",
    "Co-operative society": "Co-operative society",
    "Foreign Company": "Foreign Company",
    "Hindu Undivided Family": "Hindu Undivided Family",
    "Government Organisation": "Government Organisation",
    "Government Company": "Government Company",
  };

  const nature = {
    dta: "Domestic Tariff Area (DTA)",
    domestic: "Indian Company",
    "Foreign Company": "Foreign Company (Outside India)",
    sez: "Special Economic Zone (SEZ)",
    stpi: "Software Technology Parks of India (STPI)",
  };
  const registration = {
    regular: "Regular",
    composition: "Composition",
    unregistered: "Not Applicable",
  };

  const action_category = {
    open: "OPEN",
    obc: "OBC",
    sc: "SC/ST",
    other: "OTHER",
    dnd: "Don't wish to declare",
  };

  const msme_register = {
    Micro: "Applicable",
    Medium: "Applicable",
    Small: "Applicable",
    others: "Not Applicable",
  };

  const locations = vendor_details ? vendor_details.Locations : [];
  return (
    <div>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {value.roles[0] === "officer"
              ? "Vendor Details"
              : "Vendor Code Registration View"}
          </Grid>
          <Grid item xs={6}>
            {value.roles[0] === "user" ? (
              <Button
                onClick={() =>
                  navigate(`/app/vendor-registration-edit/${vendor_id}`)
                }
                variant="outlined"
                style={{ float: "right" }}
                disabled={isPending}
              >
                Edit
              </Button>
            ) : (
              <>
                {vendor_details?.approvalName ||
                vendor_details?.currentStatus !== true ? (
                  <div
                    style={{ display: "flex", justifyContent: "end", gap: 3 }}
                  >
                    <span
                      style={{ fontSize: "1.5rem", textDecoration: "none" }}
                    >
                      {vendor_details?.is_rejected === true ? (
                        <>Rejected by &nbsp;</>
                      ) : vendor_details?.is_pending === true &&
                        vendor_details?.currentStatus === true ? (
                        ""
                      ) : vendor_details?.is_pending === true &&
                        vendor_details?.status === "Saved as Draft" ? (
                        ""
                      ) : vendor_details?.is_pending === true ? (
                        <>Awaiting from &nbsp;</>
                      ) : (
                        ""
                      )}
                    </span>
                    {vendor_details?.is_approved === true ? (
                      <Button variant="outlined" color="success">
                        Registered
                      </Button>
                    ) : vendor_details?.is_pending === true &&
                      vendor_details?.status === "Saved as Draft" ? (
                      <Button variant="outlined" color="warning">
                        Saved as Draft
                      </Button>
                    ) : vendor_details?.is_pending === true &&
                      vendor_details?.currentStatus === true ? (
                      ""
                    ) : (
                      <Tooltip title={vendor_details?.reason}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() =>
                            setReasonOpen({
                              isOpen: true,
                              message: vendor_details?.reason,
                            })
                          }
                        >
                          {vendor_details?.approvalName
                            ? vendor_details?.approvalName[0]?.toUpperCase()
                            : ""}
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {vendor_details?.currentStatus === true ? (
                  <>
                    <ApproveButton
                      onClick={handleClickOpen}
                      variant="outlined"
                      color="success"
                      style={{
                        height: "40px",
                        width: "150px",
                        float: "right",
                      }}
                    >
                      Approve
                    </ApproveButton>
                    <RejectButton
                      onClick={handleClickOpenReject}
                      variant="outlined"
                      color="error"
                      style={{
                        height: "40px",
                        width: "150px",
                        float: "right",
                        marginInline: "1rem",
                      }}
                    >
                      Reject
                    </RejectButton>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Grid>
        </Grid>
      </AdminTitleHolder>

      <Accordion
        style={{ border: "none", backgroundColor: "#f5f7f9" }}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{
            background: "#4059e5",
            color: "white",
            marginBottom: "15px",
          }}
          className="downArrow"
        >
          <Typography>
            <b>Vendor Details</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ border: "none" }}>
          {!value?.roles.includes("user") && client?.id === 2 && (
            <StyledCard>
              <CardContent>
                <VendorCardTitle>General information</VendorCardTitle>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography className="label">
                      Inviter Officer Name
                    </Typography>
                    <VendorCardLabel>
                      {vendor_details.inviterName
                        ? vendor_details.inviterName
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  {client?.id === 2 && (
                    <>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          Inviter Company
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.inviterCompany
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          Vendor Category
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.vendorCategory
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>
                    </>
                  )}
                  {dev && (
                    <Grid item xs={6} md={4}>
                      <Typography className="label">
                        Is Existing Vendor
                      </Typography>
                      <VendorCardLabel>
                        {vendor_details
                          ? vendor_details.permanent_account_number !== null
                            ? vendor_details.permanent_account_number
                            : "Not Applicable"
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </StyledCard>
          )}
          <StyledCard>
            <CardContent>
              <VendorCardTitle>Statutory information</VendorCardTitle>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Typography className="label">Company name</Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? vendor_details.company_name
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography className="label">Company e-mail</Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? vendor_details.company_email
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography className="label">Company PAN</Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? vendor_details.permanent_account_number !== null
                        ? vendor_details.permanent_account_number
                        : "Not Applicable"
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Typography className="label">Legal name</Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? vendor_details.legal_name !== null
                        ? vendor_details.legal_name
                        : "Not Applicable"
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    Company constitution
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? constitutions[vendor_details?.company_constitution]
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography className="label">Company nature</Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? nature[vendor_details.company_nature]
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    Affirmative action category
                  </Typography>
                  <VendorCardLabel style={{ textTransform: "capitalize" }}>
                    {vendor_details?.affirmative_action_category &&
                    vendor_details?.affirmative_action_category !== "null"
                      ? action_category[
                          vendor_details.affirmative_action_category
                        ]
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                {vendor_details?.is_domestic && (
                  <Grid item xs={6} md={4}>
                    <Typography className="label">
                      Registration type(GST)
                    </Typography>
                    <VendorCardLabel>
                      {vendor_details
                        ? registration[vendor_details.gst_registration_type]
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                )}

                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    Tax Identification Number(TIN)
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? vendor_details.tax_identification_number &&
                        vendor_details.tax_identification_number !== "null"
                        ? vendor_details.tax_identification_number
                        : "Not Applicable"
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                {/* </Grid>
              <br />
              <Grid container spacing={2}> */}
                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    Annual turnover (in Million)
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details.annual_turnover
                      ? annual.USD
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    Annual turnover (in Crore)
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details.annual_turnover
                      ? annual.IND
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {vendor_details?.is_domestic && (
            <StyledCard>
              <CardContent>
                <VendorCardTitle>
                  <Grid container>MSME information</Grid>
                </VendorCardTitle>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography className="label">
                      Are you MSME registered?
                    </Typography>
                    <VendorCardLabel>
                      {vendor_details?.Msme?.msme_type !== "null" &&
                      vendor_details?.Msme?.msme_type !== null
                        ? msme_register[vendor_details?.Msme?.msme_type]
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  {vendor_details?.Msme?.msme_type !== "others" ? (
                    <>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          MSME registration number
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.Msme?.registration_number
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">MSME Type</Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.Msme?.msme_type
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          Enterprise Activity
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.Msme?.enterprise_activity
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          Classification Date
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.Msme?.classification_date
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          Classification Year
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details
                            ? vendor_details?.Msme?.classification_year
                            : "Not Applicable"}
                        </VendorCardLabel>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          MSME Document{" "}
                        </Typography>
                        {vendor_details.Documents?.length ? (
                          vendor_details.Documents[0].msme_certificate_image ? (
                            <CustomDocumentLink
                              href={
                                vendor_details.Documents[0]
                                  .msme_certificate_image
                              }
                              target="_blank"
                            >
                              <InsertDriveFileOutlinedIcon
                                style={{
                                  marginTop: "-1px",
                                  marginRight: "3px",
                                  height: "12px",
                                  width: "12px",
                                }}
                              />
                              {
                                vendor_details.Documents[0]
                                  .msme_certificate_image_name
                              }
                            </CustomDocumentLink>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </CardContent>
            </StyledCard>
          )}

          <StyledCard>
            <CardContent>
              <VendorCardTitle>Documents</VendorCardTitle>

              <Grid container spacing={2}>
                {value.is_domestic === true ||
                vendor_details.is_domestic === true ? (
                  <Grid item xs={6} md={4}>
                    <Typography className="label">PAN copy</Typography>
                    <VendorCardLabel>
                      {vendor_details.Documents?.length ? (
                        vendor_details.Documents[0].pan_image_name ? (
                          <CustomDocumentLink
                            href={vendor_details.Documents[0].pan_image}
                          >
                            <InsertDriveFileOutlinedIcon
                              style={{
                                marginTop: "-1px",
                                marginRight: "3px",
                                height: "12px",
                                width: "12px",
                              }}
                            />
                            {vendor_details.Documents[0].pan_image_name}
                          </CustomDocumentLink>
                        ) : (
                          "Not Applicable"
                        )
                      ) : (
                        "Not Applicable"
                      )}
                    </VendorCardLabel>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    {!bank_info
                      ? "Dealer authorization certificate"
                      : "vendor register form"}
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details ? (
                      vendor_details.Documents?.length ? (
                        vendor_details.Documents[0]
                          .dealer_authorization_image_name ? (
                          <CustomDocumentLink
                            href={
                              vendor_details.Documents[0]
                                .dealer_authorization_image
                            }
                          >
                            <InsertDriveFileOutlinedIcon
                              style={{
                                marginTop: "-1px",
                                marginRight: "3px",
                                height: "12px",
                                width: "12px",
                              }}
                            />
                            {
                              vendor_details.Documents[0]
                                .dealer_authorization_image_name
                            }
                          </CustomDocumentLink>
                        ) : (
                          "Not Applicable"
                        )
                      ) : (
                        "Not Applicable"
                      )
                    ) : (
                      ""
                    )}
                  </VendorCardLabel>
                </Grid>

                {!bank_info ? (
                  <>
                    <Grid item xs={6} md={4}>
                      <Typography className="label">TIN certificate</Typography>
                      <VendorCardLabel>
                        {vendor_details.Documents?.length ? (
                          vendor_details.Documents[0]
                            .tin_certificate_image_name ? (
                            <CustomDocumentLink
                              href={
                                vendor_details.Documents[0]
                                  .tin_certificate_image
                              }
                            >
                              <InsertDriveFileOutlinedIcon
                                style={{
                                  marginTop: "-1px",
                                  marginRight: "3px",
                                  height: "12px",
                                  width: "12px",
                                }}
                              />
                              {
                                vendor_details.Documents[0]
                                  .tin_certificate_image_name
                              }
                            </CustomDocumentLink>
                          ) : (
                            "Not Applicable"
                          )
                        ) : (
                          "Not Applicable"
                        )}
                      </VendorCardLabel>
                    </Grid>
                    {value.is_domestic === false ? (
                      <Grid item xs={6} md={4}>
                        <Typography className="label">
                          ISO, RSDO and any additional certificate
                        </Typography>
                        <VendorCardLabel>
                          {vendor_details.Documents?.length ? (
                            vendor_details.Documents[0]
                              .iso_or_rsdo_or_any_image_name ? (
                              <CustomDocumentLink
                                href={
                                  vendor_details.Documents[0]
                                    .iso_or_rsdo_or_any_image
                                }
                              >
                                <InsertDriveFileOutlinedIcon
                                  style={{
                                    marginTop: "-1px",
                                    marginRight: "3px",
                                    height: "12px",
                                    width: "12px",
                                  }}
                                />
                                {
                                  vendor_details.Documents[0]
                                    .iso_or_rsdo_or_any_image_name
                                }
                              </CustomDocumentLink>
                            ) : (
                              "Not Applicable"
                            )
                          ) : (
                            "Not Applicable"
                          )}
                        </VendorCardLabel>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <br />
                    {value.is_domestic === true ? (
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                          <Typography className="label">
                            ISO, RSDO and any additional certificate
                          </Typography>
                          <VendorCardLabel>
                            {vendor_details.Documents?.length ? (
                              vendor_details.Documents[0]
                                .iso_or_rsdo_or_any_image_name ? (
                                <CustomDocumentLink
                                  href={
                                    vendor_details.Documents[0]
                                      .iso_or_rsdo_or_any_image
                                  }
                                >
                                  <InsertDriveFileOutlinedIcon
                                    style={{
                                      marginTop: "-1px",
                                      marginRight: "3px",
                                      height: "12px",
                                      width: "12px",
                                    }}
                                  />
                                  {
                                    vendor_details.Documents[0]
                                      .iso_or_rsdo_or_any_image_name
                                  }
                                </CustomDocumentLink>
                              ) : (
                                "Not Applicable"
                              )
                            ) : (
                              "Not Applicable"
                            )}
                          </VendorCardLabel>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                <Grid item xs={6} md={4}>
                  <Typography className="label">
                    {!bank_info
                      ? "Supported Documents"
                      : "Any Others Documents"}
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details
                      ? vendor_details.Documents?.length
                        ? vendor_details.Documents[0]
                            .supporting_document_image &&
                          vendor_details.Documents[0]?.supporting_document_image.map(
                            (data, index) => {
                              return (
                                <div>
                                  <CustomDocumentLink href={data}>
                                    <InsertDriveFileOutlinedIcon
                                      style={{
                                        marginTop: "-1px",
                                        marginRight: "3px",
                                        height: "12px",
                                        width: "12px",
                                      }}
                                    />
                                    {
                                      vendor_details.Documents[0]
                                        .supporting_document_image_name[index]
                                    }
                                  </CustomDocumentLink>
                                </div>
                              );
                            }
                          )
                        : "Not Applicable"
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          <StyledCard>
            <CardContent>
              <VendorCardTitle>Additional Information</VendorCardTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography className="label">HSN/SAC codes</Typography>
                  {vendor_details?.hsn_codes ? (
                    vendor_details?.hsn_codes.map((item, index) => (
                      <Chip
                        style={{
                          color: "#4945FF",
                          margin: "0 5px 10px 0 ",
                          borderRadius: "5px",
                          border: "1px solid #D9D8FF",
                          background: "#F0F0FF",
                        }}
                        key={index}
                        label={item}
                      />
                    ))
                  ) : (
                    <Typography className="label">No codes slected</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography className="label">
                    Additional business areas
                  </Typography>
                  <VendorCardLabel>
                    {vendor_details?.business_interest_areas
                      ? vendor_details?.business_interest_areas
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{ backgroundColor: "#f5f7f9", border: "none" }}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          style={{ background: "#4059e5", color: "white" }}
          className="downArrow"
        >
          <Typography>
            <b>Business Details</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: "10px" }}>
          <Grid container spacing={2}>
            {locations
              ? locations.map((item, index) => (
                  <Grid key={index} item xs={12} md={6} sm={12}>
                    <StyledCard>
                      <CardHeader
                        action={
                          item.gst_document_image ? (
                            <CustomDocumentLink href={item.gst_document_image}>
                              <Tooltip title="Download GST">
                                <DownloadIcon
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "28px",
                                    marginRight: "307",
                                    marginBottom: "-4px",
                                  }}
                                />
                              </Tooltip>
                            </CustomDocumentLink>
                          ) : (
                            ""
                          )
                        }
                        title={
                          item.location ? (
                            <VendorCardTitle style={{ padding: "0" }}>
                              {item.location}
                            </VendorCardTitle>
                          ) : (
                            <VendorCardTitle>{item.state}</VendorCardTitle>
                          )
                        }
                        subheader={
                          // eslint-disable-next-line
                          item.gst_id != null && item.gst_id != ""
                            ? "GST: " + item.gst_id
                            : // eslint-disable-next-line
                              "GST: " + "Not Applicable"
                        }
                      />

                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">Country</Typography>
                            <VendorCardLabel>{item.country}</VendorCardLabel>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">State</Typography>
                            <VendorCardLabel>{item.state}</VendorCardLabel>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">City</Typography>
                            <VendorCardLabel>{item.city}</VendorCardLabel>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">
                              Address line 1
                            </Typography>
                            <VendorCardLabel>
                              {item.address_line_one}
                            </VendorCardLabel>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">
                              Address line 2
                            </Typography>
                            <VendorCardLabel>
                              {item.address_line_two
                                ? item.address_line_two
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">
                              Postal code
                            </Typography>
                            <VendorCardLabel>
                              {item.postal_code
                                ? item.postal_code
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={4} sm={6}>
                            <Typography className="label">Phone</Typography>
                            <VendorCardLabel>{item.phone}</VendorCardLabel>
                          </Grid>
                          <Grid item xs={6} md={4} sm={6}>
                            <Typography className="label">
                              Branch e-mail
                            </Typography>
                            <VendorCardLabel>
                              {item.branch_email.slice(0, 20) <
                              item.branch_email ? (
                                <>
                                  <Tooltip title={item.branch_email}>
                                    <VendorCardLabel>
                                      {item.branch_email.slice(0, 20)}...
                                    </VendorCardLabel>
                                  </Tooltip>
                                </>
                              ) : (
                                <VendorCardLabel>
                                  {item.branch_email}
                                </VendorCardLabel>
                              )}
                            </VendorCardLabel>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography className="label">
                              Fax number
                            </Typography>
                            <VendorCardLabel>
                              {item.fax_number
                                ? item.fax_number
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions disableSpacing>
                        <VendorCardLabel
                          style={{
                            fontFamily: "var(--font-family-bold)",
                            paddingLeft: "8px",
                            fontSize: "15px",
                            /* fontWeight: "500", */
                          }}
                        >
                          Contacts
                        </VendorCardLabel>
                        <ExpandMore
                          expand={businessexpanded === index ? true : false}
                          onClick={(e) => handleExpandClick(e, index)}
                          aria-expanded={
                            businessexpanded === index ? true : false
                          }
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                      <Collapse
                        in={businessexpanded === index ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        {item?.Contacts.length ? (
                          item?.Contacts.map((item, index) => (
                            <CardContent key={index}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={4}>
                                  <Typography className="label">
                                    Name
                                  </Typography>
                                  <VendorCardLabel>
                                    {item.contact_first_name +
                                      " " +
                                      item.contact_last_name}
                                  </VendorCardLabel>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                  <Typography className="label">
                                    Phone
                                  </Typography>
                                  <VendorCardLabel>
                                    {item.mobile_number}
                                  </VendorCardLabel>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                  <Typography className="label">
                                    Branch e-mail
                                  </Typography>
                                  <VendorCardLabel>
                                    {item.email.slice(0, 20) < item.email ? (
                                      <>
                                        <Tooltip title={item.email}>
                                          <VendorCardLabel>
                                            {item.email.slice(0, 20)}...
                                          </VendorCardLabel>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <VendorCardLabel>
                                        {item.email}
                                      </VendorCardLabel>
                                    )}
                                  </VendorCardLabel>
                                </Grid>
                              </Grid>
                            </CardContent>
                          ))
                        ) : (
                          <CardContent>
                            <VendorCardLabel>
                              <InfoIcon
                                sx={{
                                  fill: "grey",
                                  height: "18px",
                                  width: "18px",
                                }}
                              />{" "}
                              {"There are no contacts to display"}
                            </VendorCardLabel>
                          </CardContent>
                        )}
                      </Collapse>
                    </StyledCard>
                  </Grid>
                ))
              : ""}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {(bank_info && value.roles[0] !== "user") ||
      getBanksDetails.length !== 0 ? (
        <Accordion
          style={{
            backgroundColor: "#f5f7f9",
            border: "none",
            marginTop: "15px",
          }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            style={{ background: "#4059e5", color: "white" }}
            className="downArrow"
          >
            <Typography>
              <b>Bank Details</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "10px" }}>
            <Grid container spacing={2}>
              {getBanksDetails
                ? getBanksDetails.map((item, index) => (
                    <Grid key={index} item>
                      <BankInfoDetails
                        item={item}
                        is_domestic={vendor_details?.is_domestic}
                      />
                    </Grid>
                  ))
                : "no Data found"}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to approve this vendor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "rgb(28 182 151)",
              color: "#0f0f0f",
            }}
            size="small"
            // color="success"
          >
            Cancel
          </Button>
          <ApproveButton
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            onClick={(e) => handleApprove(vendor_id)}
            autoFocus
            size="small"
          >
            Approve
          </ApproveButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRej}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            placeholder="Add custom text"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseRej}
            variant="outlined"
            style={{
              borderColor: "rgb(211 47 47)",
              color: "#0f0f0f",
            }}
            size="small"
          >
            Cancel
          </Button>
          <RejectButton
            color="error"
            variant="contained"
            style={{
              float: "right",
            }}
            onClick={(e) => handleReject(vendor_id)}
            autoFocus
            size="small"
          >
            Reject
          </RejectButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reasonOpen.isOpen}
        onClose={() => setReasonOpen({ isOpen: false, message: "" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Rejection Reason"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            dangerouslySetInnerHTML={{ __html: reasonOpen?.message }}
          >
            {/* {reasonOpen.message} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setReasonOpen({ isOpen: false, message: "" })}
            variant="outlined"
            style={{
              borderColor: "rgb(28 182 151)",
              color: "#0f0f0f",
            }}
            size="small"
            // color="success"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ApproveButton = styled(Button)`
  border-color: var(--button-color);
  color: var(--button-color);
  :hover {
    border-color: var(--button-color);
    background-color: var(--button-color);
    color: white;
  }
`;

export const RejectButton = styled(Button)`
  :hover {
    background-color: red;
    color: white;
  }
`;
