import { Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import {
  AddButton,
  // ButtonLink,
} from "../bankDetails/components/bankAccountsContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminGrnsTableContent from "./components/adminGrnsTableContent";
// import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// for dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";

// import { read, utils } from "xlsx";
// import * as XLSX from "xlsx";

// import { styled } from "@mui/system";
// import { StyledOutlineButton } from "../vendorRegistration/components/StepMain";
import { addExcelData } from "./redux/adminGrnsSlice";
import { useDispatch } from "react-redux";
// import { history } from "../../app/history";
// const Input = styled("input")({
//   display: "none",
// });
function AdminGrnsTable() {
  const [
    data,
    // setData
  ] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [emptyOpen, setEmptyOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // const handleFileUpload = (e) => {
  //   const files = e.target.files;

  //   if (files.length) {
  //     const file = files[0];
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const wb = read(event.target.result);
  //       const sheets = wb.SheetNames;
  //       if (sheets.length) {
  //         const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
  //         if (rows.length !== 0) {
  //           setOpen(true);
  //           setData(rows);
  //         } else {
  //           setEmptyOpen(true);
  //         }
  //       }
  //     };
  //     reader.readAsArrayBuffer(file);
  //   }
  // };

  const uploadData = () => {
    dispatch(addExcelData({ data })).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        setOpen(false);
        // history.push("/app/orders-list");
      } else {
        setLoading(false);
        setOpen(false);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEmptyOpen(false);
  };

  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Goods Received Notes
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                gap: 3,
              }}
            >
              {/* <label>
                <Input
                  type="file"
                  accept=".xlsx .xls .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileUpload}
                />
                <StyledOutlineButton
                  variant="outlined"
                  component="span"
                  style={{ borderColor: "#4059e5" }}
                >
                  Upload
                </StyledOutlineButton>
              </label> */}
              {/* dialog box for conformation */}
              <Dialog
                open={open}
                onClose={handleClose}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle
                  style={{ cursor: "move" }}
                  id="draggable-dialog-title"
                >
                  Upload Excel Data
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure add excel data?
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ alignItems: "right" }}>
                  <Button
                    autoFocus
                    onClick={handleClose}
                    style={{ border: "#4059e5 solid 1px", height: "30px" }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    sx={{
                      textTransform: "none",
                      background: "#4059e5",
                      width: "70px",
                      height: "30px",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={uploadData}
                    loading={loading}
                    loadingPosition="start"
                  >
                    GRN
                  </LoadingButton>
                </DialogActions>
              </Dialog>
              {/* this dialod for no data in excel */}
              <Dialog
                open={emptyOpen}
                onClose={handleClose}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle
                  style={{ cursor: "move" }}
                  id="draggable-dialog-title"
                >
                  Subscribe
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    please enter data in excel
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    style={{ border: "#4059e5 solid 1px", height: "30px" }}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <Tooltip title="Create a GRN">
                <AddButton
                  style={{ backgroundColor: "#4059e5" }}
                  replace
                  to="/app/grns"
                ></AddButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <AdminGrnsTableContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminGrnsTable;
