import React, { useEffect } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
// import BankDetailsContent from "./components/bankDetailsContent";
import BankDetailsContent1 from "./components/bankDetailsContent1";
import LocalStorage from "../../utilities/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../dashboard/redux/dashboardSlice";
import { message } from "antd";
import { getNotification } from "../notification/redux/notificationSlice";
import { history } from "../../app/history";
import { useNavigate } from "react-router-dom";

function BankDetails() {
  const is_domestic = LocalStorage.getItem("tata_login_user")?.is_domestic;
  const { vendorDetails } = useSelector((state) => state.dashboarddetails);
  const { notification } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (
    vendorDetails &&
    vendorDetails?.is_pending &&
    vendorDetails?.status === "Registered"
  ) {
    navigate(-1);
    message.error("You are in workflow");
  }

  useEffect(() => {
    if (!vendorDetails || !vendorDetails.length) {
      dispatch(getDashboard());
    }
    dispatch(getNotification());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const checkVendorInfo = notification?.filter(
      (data) => data.category === "vendor"
    );
    if (checkVendorInfo?.length) {
      history.push("/app");
      message.error("complete vendor Details Information");
    }
  }, [notification]);
  return (
    <div>
      <TitleHolder>Add Bank Information </TitleHolder>
      <StepperContentHolder>
        <BankDetailsContent1 is_domestic={is_domestic} />
      </StepperContentHolder>
    </div>
  );
}

export default BankDetails;
