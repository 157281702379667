import { Grid } from "@mui/material";
import React, { useState } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import BankAccountsContent, {
  AddButton,
} from "./components/bankAccountsContent";
import info from "../../assets/icon.png";
import { VRSubTitle } from "../vendorRegistration/components/StepMain";
import { Link } from "react-router-dom";
import LocalStorage from "../../utilities/localStorage";
function BankAccounts() {
  const clientId = LocalStorage.getItem("client")?.id;
  const [isMultiple, setisMiltiple] = useState(true);
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Bank Information
          </Grid>
          {clientId === 2 && isMultiple && (
            <Grid sx={{ marginTop: "15px" }} item xs={6}>
              <div style={{ textAlign: "right" }}>
                <AddButton replace to="/app/bank-details/add-bank"></AddButton>
              </div>
            </Grid>
          )}
        </Grid>
      </TitleHolder>
      <StepperContentHolder>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <VRSubTitle style={{ marginTop: "-30px" }}>
              {clientId !== 2 && (
                <>
                  <img src={info} alt="icon" /> Vendors can add one primary
                  account and multiple secondary accounts
                </>
              )}
            </VRSubTitle>
          </Grid>
        </Grid>
        <Link style={{ textDecoration: "none", color: "#4059e5" }} to="/app">
          Back
        </Link>
      </StepperContentHolder>

      <StepperContentHolder>
        <BankAccountsContent setChildData={setisMiltiple} />
      </StepperContentHolder>
    </div>
  );
}

export default BankAccounts;
