import React from "react";
import CategoryAddContent from "./components/categoryAddContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

const CategoryAdd = () => {
  return (
    <div>
      <TitleHolder>Vendor Category</TitleHolder>
      <StepperContentHolder>
        <CategoryAddContent />
      </StepperContentHolder>
    </div>
  );
};

export default CategoryAdd;
