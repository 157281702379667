import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import CategoryTableContent from "./components/categoryTableContent";
import { endpoint } from "../../utilities/redux-api/endpoint";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { history } from "../../app/history";

const CategoryTable = () => {
  const [search, setSearch] = React.useState(null);
  return (
    <div>
      <TitleHolder>
        <Grid container>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Vendor Category
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "end", gap: "1rem" }}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              <TextField
                variant="standard"
                size="small"
                label="Search by Type name & code"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Tooltip title="Other Options">
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "2rem",
                          height: "2.5rem",
                          width: "4.3rem",
                        }}
                        {...bindTrigger(popupState)}
                      >
                        {popupState?.isOpen ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )}
                      </Button>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() =>
                          history.push(`/app/${endpoint.vendor_category}/add`)
                        }
                      >
                        {<Add color="primary" />} Create Category
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Grid>
          </Grid>
        </Grid>
      </TitleHolder>
      <StepperContentHolder>
        <CategoryTableContent search={search} />
      </StepperContentHolder>
    </div>
  );
};

export default CategoryTable;
