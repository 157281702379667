import { apiUrl, getLocalToken } from "../../apiClient";
import { endpoint } from "../endpoint";
const { createApi, fetchBaseQuery } = require("@reduxjs/toolkit/query/react");

export const sap_api = createApi({
  reducerPath: "sap",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/api`,
    prepareHeaders: (headers) => {
      let token = getLocalToken();
      if (token) {
        headers.set("Authorization", token);
      }
      headers.set = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAllSAP: builder.query({
      query: () => `${endpoint.sap}`,
    }),
  }),
});

export const { useGetAllSAPQuery } = sap_api;
