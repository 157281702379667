import React from "react";
import LocalStorage from "../../../../utilities/localStorage";
import { useGetTenantDetailsQuery } from "../../../../utilities/redux-api/api";
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { message } from "antd";
// import { AdminTitleHolder } from "../../../../common/sharedComponents/styles/card.style";
import {
  StyledCard,
  VendorCardLabel,
} from "../../../vendorRegistration/components/StepMain";
import moment from "moment";

const TenantViewContent = () => {
  const TenantId = LocalStorage.getItem("client")?.id;
  const { data, error, isLoading } = useGetTenantDetailsQuery(TenantId);

  if (error) return message.error(error.message);
  return (
    <>
      <StyledCard>
        <CardContent>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={3} md={3}>
                <Typography className="label">COMPANY NAME</Typography>
                <VendorCardLabel>
                  {data?.company_name || "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">DOMAIN URL</Typography>
                <VendorCardLabel>
                  {data?.domain_url || "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">SMTP EMAIL</Typography>
                <VendorCardLabel>
                  {data?.smtp_email || "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">SMTP PORT</Typography>
                <VendorCardLabel>
                  {data?.smtp_port || "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">SMTP HOST</Typography>
                <VendorCardLabel>
                  {data?.smtp_host || "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">SMTP SERVICE</Typography>
                <VendorCardLabel>
                  {data?.smtp_service || "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">CREATE DATE</Typography>
                <VendorCardLabel>
                  {moment(data?.updatedAt).format("DD MMM YY") ||
                    "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography className="label">LAST UPDATE DATE</Typography>
                <VendorCardLabel>
                  {moment(data?.updatedAt).format("DD MMM YY") ||
                    "Not Applicable"}
                </VendorCardLabel>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </StyledCard>
    </>
  );
};

export default TenantViewContent;
