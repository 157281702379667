export const support_role_endpoint = {
  tenant: "support/tenant",
  user: "support/users",
  company: "support/company",
};

export const endpoint = {
  vendor_category: "vendor_category",
  sap: "sap",
};
