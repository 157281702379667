import apiClient from "../../../utilities/apiClient";

export const get_pr_granted_users = (page, per_page) => {
  return apiClient.get(
    `${apiClient.Urls.get_pr_granted_users}?page=${page}&per_page=${per_page}`,
    "",
    true
  );
};

export const get_pr_pending_users = (page, per_page) => {
  return apiClient.get(
    `${apiClient.Urls.get_pr_pending_users}?page=${page}&per_page=${per_page}`,
    "",
    true
  );
};

export const get_pr_revoked_users = (page, per_page) => {
  return apiClient.get(
    `${apiClient.Urls.get_pr_revoked_users}?page=${page}&per_page=${per_page}`,
    "",
    true
  );
};

export const grant_pr_privilege = (payload) => {
  return apiClient.post(`${apiClient.Urls.grant_pr_privilege}`, payload, true);
};

export const revoke_pr_privilege = (payload) => {
  return apiClient.post(`${apiClient.Urls.revoke_pr_privilege}`, payload, true);
};
